<template>
  <div
    v-expose="{
      id: '1-6-1-268',
      data: {
        loc
      }
    }"
    v-tap="{
      id: '1-6-1-269',
      data: {
        loc
      }
    }"
    class="recommend-refreshcard"
    @click="clickRefresh"
  >
    <p class="recommend-refreshcard__title">
      {{ refreshTexts.refreshCardTitle }}
    </p>
    <p class="recommend-refreshcard__desc">
      {{ refreshTexts.refreshCardDesc }}
    </p>
    <div
      class="recommend-refreshcard__btn"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M3.94263 5.45374C4.85023 3.76958 6.63017 2.625 8.67743 2.625C11.646 2.625 14.0524 5.03147 14.0524 8C14.0524 10.9685 11.646 13.375 8.67743 13.375C6.63017 13.375 4.85023 12.2304 3.94263 10.5463"
          stroke="#222222"
          stroke-width="1.2"
        />
        <path
          d="M3.24121 3.19385L3.45799 5.75667L6.11682 5.53177"
          stroke="#222222"
          stroke-width="1.2"
        />
      </svg>
      <p class="recommend-refreshcard__btn-text">{{ refreshTexts.refreshCardBtn }}</p>
    </div>
  </div>
</template>
<script setup name="RefreshCard">
import { vExpose, vTap } from 'public/src/pages/common/analysis/directive'
const props = defineProps({
  refreshClick: {
    type: Function,
    default: () => {}
  },
  loc: {
    type: [Number, String],
    default: ''
  },
  refreshTexts: {
    type: Object,
    default: () => ({})
  }
})
const clickRefresh = () => {
  props.refreshClick?.()
}
</script>

<style lang="less">
.recommend-refreshcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #FAF4F7 -0.28%, #E7F1FD 99.72%);
  border-radius: .1067rem;
  padding: 0 .32rem .3733rem;
  margin-bottom: 0.16rem;

  &__title {
    font-family: SF Pro;
    font-weight: 700;
    font-size: .3467rem;
    line-height: .4136rem;
    letter-spacing: 0px;
    text-align: center;

    width: 4.1333rem;
    margin-top: .3733rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__desc {
    font-family: SF Pro;
    font-weight: 400;
    font-size: .32rem;
    line-height: .3819rem;
    letter-spacing: 0px;
    text-align: center;

    width: 4.1333rem;
    margin-top: .16rem;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__btn {
    display: flex;
    align-items: center;

    height: .7467rem;
    padding: 0 .32rem;

    margin-top: .3733rem;

    border: .0267rem solid #000;
    border-radius: .3733rem;
  }

  // &__btn-icon {

  // }
  &__btn-text {
    font-family: SF UI Text;
    font-weight: 400;
    font-size: .3733rem;
    line-height: 1;
    letter-spacing: 0px;
    text-align: center;
    margin-left: .1067rem;
  }

}
</style>
