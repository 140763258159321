
import { computed, ref, unref, onMounted, onUnmounted, watch } from 'vue'
import { showSurveyComponent } from 'public/src/pages/components/product/carrier_ui/survey-question/utils'
import { getSurveyInfo } from 'public/src/pages/components/product/carrier_ui/survey-question/utils'
import { useMapGetters } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { insertPitDataToTab, PIT_DATA_TYPE } from '../RecommendBottom/insertPit.js'

const getData = async () => {

  // return {
  //   info: {
  //     'deliveryStyle': 'NotShow',
  //     'showPosition': '4',
  //     'surveyUrl': '问卷链接',
  //     'loseConfig': {
  //       'days': '2',
  //       'times': '1'
  //     },
  //     'mainTitle': '主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案主标题文案',
  //     'subTitle': '副标题文案副标题文案副标题文案',
  //     'surveyId': '12345'
  //   }
  // }
  const params = {
    entrance_type: 'PRODUCT_DETAIL_RECOMMEND', // 商详推荐
    page_type: 'productDetailsRecPage', // 商详推荐
  }

  const res = await getSurveyInfo(params)
  return res
}

export function getConstantData() {
  const {
    LAZY_IMG,
    LAZY_IMG_PIXEL,
    IMG_LINK,
    LAZY_IMG_SOLID_COLOR,
    GB_cssRight, 
    IS_RW,
    langPath,
    PUBLIC_CDN,
    lang,
    currency,
    currencies,
    appLanguage
  } = typeof window === 'undefined' ? {} : gbCommonInfo
  const constantData = ref({ LAZY_IMG, LAZY_IMG_PIXEL, IMG_LINK, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW, langPath, PUBLIC_CDN, lang, currency, currencies, appLanguage })

  return {
    constantData,
  }
}


export const useSurveyQuestion = () => {

  const { language_v2 } = useMapGetters('productDetail/common', ['language_v2'])
  const surveyInfo = ref({})
  const loaded = ref(false)
  const { constantData } = getConstantData()


  const initSurveyQuestion = async () => {
    surveyInfo.value = {}
    const { info } = (await getData() || {})
    loaded.value = true
    if (!info?.surveyId) return

    console.log('initSurveyQuestion info', info)
    if (!showSurveyComponent(info)) return

    surveyInfo.value = info
  }

  let _unWatchFn = null
  const waitData = () => {
    return new Promise((resolve) => {
      if (loaded?.value) return resolve(true)
      _unWatchFn = watch(() => loaded.value, () => {
        resolve(true)
      },
      {
        once: true,
      })
    })
  }

  const getSurveyQuestionShowPosition = (firstPageShowProductsLength) => {
  /**
   * 如果返回的插坑位置超出了当前页面的能加载的所有商品数量的话，就放在最后一个，例如：
    如果后端传给下游分页数，当前页允许展示10个，商品数量为10个，问卷插坑位置为15，则插在11坑；
    如果后端传给下游分页数，当前页允许展示20个，商品数量为10个，问卷插坑位置为15，则插在11坑；
   */
    if (!firstPageShowProductsLength) return -1
    const { showPosition } = unref(surveyInfo) || {}
    if (typeof showPosition === 'undefined') return -1

    if (firstPageShowProductsLength < showPosition) return firstPageShowProductsLength + 1
    return showPosition
  }

  const cSurveyQuestionComBindProps = computed(() => {
    if (!surveyInfo.value?.surveyId) return

    return {
      surveyInfo: surveyInfo.value,
      language: unref(language_v2),
      constantData: unref(constantData),
    }
  })

  const setSurveyQuestionEmpty = () => {
    surveyInfo.value = {}
  }

  const onSurveyComponentExposed = (e) => {
    const _surveyInfo = unref(surveyInfo)
    if (e?.surveyInfo?.surveyId != _surveyInfo?.surveyId) return
    if (showSurveyComponent(_surveyInfo)) return
    setSurveyQuestionEmpty() // 当前链路未曝光过，其他曝光后要隐藏
  }

  onMounted(() => {
    appEventCenter.on('surveyComponentExposed', onSurveyComponentExposed)
  })
  onUnmounted(() => {
    appEventCenter.off('surveyComponentExposed', onSurveyComponentExposed)
    _unWatchFn && _unWatchFn?.()
  })


  const insterSurverQuestion = async (tabItem) => {

    if (!tabItem) return
    typeof _unWatchFn === 'function' && _unWatchFn()
    await waitData()
    // console.log('outfitInYmalInfo', outfitInYmalInfo)
    if (!cSurveyQuestionComBindProps.value) return
    const { products, total, pitPositionList } = tabItem || {}
    if (!products.length) return
    const showPosition = getSurveyQuestionShowPosition(total > products.length ? total : products.length)
    if (showPosition === -1) return

    if (!pitPositionList) return

    const pitData = {
      _slotType: PIT_DATA_TYPE.surveyQuestion,
      _pitIndex: showPosition - 1,
    }
    insertPitDataToTab(tabItem, pitData)
  }


  // watch(
  //   [cSurveyQuestionComBindProps, inited],
  //   ([data, isInited]) => {
  //     if (!data || !isInited) return
  //     const { products, total, pitPositionList } = tabData.value[0] || {}
  //     if (!products.length) return
  //     const showPosition = getSurveyQuestionShowPosition(total > products.length ? total : products.length)
  //     if (showPosition === -1) return
  
  //     if (!pitPositionList) return
  
  //     const pitData = {
  //       _slotType: PIT_DATA_TYPE.surveyQuestion,
  //       _pitIndex: showPosition - 1,
  //     }
  
  //     insertPitDataToTab(tabData.value[0], pitData)
  //   },
  //   { immediate: true }
  // )
  

  return {
    cSurveyQuestionComBindProps,
    initSurveyQuestion,
    getSurveyQuestionShowPosition,
    setSurveyQuestionEmpty,
    insterSurverQuestion,
  }
}

