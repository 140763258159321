<template>
  <div class="recommend-skeletoncard">
    <div class="recommend-skeletoncard__line1"></div>
    <div class="recommend-skeletoncard__line2">
    </div>
    <div class="recommend-skeletoncard__line3">
      <div></div>
      <div></div>
    </div>
    <div class="recommend-skeletoncard__line4">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script setup name="SkeletonCard">
</script>

<style lang="less">
.recommend-skeletoncard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFF;
  height: 8.4267rem;
  margin-bottom: .16rem;
  border-radius: .1067rem;
  overflow: hidden;

  &__line1 {
    width: 100%;
    height: 6.4rem;
    background: #f2f2f2;
  }

  &__line2 {
    background: #f2f2f2;
    width: calc(100% - .4267rem);
    height: .3733rem;

    margin-top: .16rem;
  }

  &__line3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - .4267rem);
    height: .64rem;

    margin-top: .0533rem;

    & > div:nth-child(1) {
      width: 3.2667rem;
      height: .5333rem;
      background: #f2f2f2;
    }
    & > div:nth-child(2) {
      width: .96rem;
      height: .64rem;
      background: #f2f2f2;
      border-radius: .64rem;
    }
  }

  &__line4 {
    width: calc(100% - .4267rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .16rem;
    & > div {
      width: 1.36rem;
      height: .3733rem;
      border-radius: .0533rem;
      background: #f2f2f2;
    }
  }


}
</style>
