import { computed, ref, watch, nextTick } from 'vue'
import { insertPitDataToTab, PIT_DATA_TYPE } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/RecommendBottom/insertPit.js'
import { useMapGetters, useMapStateV2 } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { updateTabItemIdsMap } from '../utils.js'
import { clearClickRecByIndex } from '../click-rec/click-rec'

export const useRefreshRec = ({
  activeIndex,
  tabData,
  loadNextPageData,
  reset,
}) => {

  const { fs_abt, language_v2 } = useMapGetters('productDetail/common', [
    'fs_abt',
    'language_v2'
  ])
  const { goodsReady } = useMapStateV2('newProductDetail/common', ['goodsReady'])

  const texts = {
    refreshCardTitle: language_v2?.value?.SHEIN_KEY_PWA_36216 || 'Not interested?',
    refreshCardDesc: language_v2?.value?.SHEIN_KEY_PWA_36219 || 'Refresh and change a batch of recommendations',
    refreshCardBtn: language_v2?.value?.SHEIN_KEY_PWA_36217 || 'Refresh',
    refreshCardClickSucc: language_v2?.value?.SHEIN_KEY_PWA_36220 || 'Recommendations have been refreshed'
  }

  const isRefreshDone = ref(0)

  const cardRefreshPage = ref(0)

  let isClickedRefresh = false

  const refreshNum = computed(() => {
    return parseInt(fs_abt.value.ymalrefresh?.p?.refreshnum) || 0
  })

  const isRefreshTypeCard = computed(() => {
    return refreshNum.value && fs_abt.value.ymalrefresh?.p?.refreshtype === 'button'
  })

  const isRefreshTypeAuto = computed(() => {
    return refreshNum.value && fs_abt.value.ymalrefresh?.p?.refreshtype === 'auto'
  })


  const pitRefreshCard = (tabItem) => {
    if (!isRefreshTypeCard.value) return
    insertPitDataToTab(tabItem, {
      _pitIndex: refreshNum.value,
      _slotType: PIT_DATA_TYPE.refreshCard,
    })
  }

  const refreshClick = async () => {
    let { products, pitPositionListDone } = tabData.value?.[0]
    isRefreshDone.value = isRefreshDone.value + 1
    isClickedRefresh = true
    reset()

    // 切掉刷新卡片以及后面的数据插入骨架卡片 4 个
    const reserveProducts = products.slice(0, refreshNum.value)
    reserveProducts.forEach(item => {
      if (item.isFashionStore) {
        delete item.isFashionStore
        delete item._slotType
      }
      if (item.isTrendContent) {
        delete item.isTrendContent
        delete item._slotType
      }
    })
    const skeletonList = [0, 1, 2, 3].map(item => {
      return {
        _pitIndex: refreshNum.value + item,
        _slotType: PIT_DATA_TYPE.skeletonCard,
        _sourceIndex: refreshNum.value + item,
      }
    })
    // 清除掉下标后的点推数据
    clearClickRecByIndex(tabData.value?.[0], refreshNum.value)

    tabData.value[0].deleteItemsFlag = true // 开启删除模式，对瀑布流商品进行删减需要开启
    tabData.value[0].products = [...reserveProducts, ...skeletonList]


    updateTabItemIdsMap(tabData.value[0]) // 重置当前推荐列表的商品ids

    await nextTick() // 延迟等待use-req-actions中的数据更新
    let page = 1
    await loadNextPageData(page, 0)
    cardRefreshPage.value = page

    SToast({
      htmlString: `<div style="padding: .32rem .2667rem;line-height: .4267rem">${texts.refreshCardClickSucc}</div>`
    }, 3000)

    // 插坑还原
    let _pitPositionListDone = pitPositionListDone.map(item => {
      if (item._pitIndex > refreshNum.value) {
        tabData.value[0].pitPositionList.push(item)
        return null
      }
      return item
    }).filter(item => !!item)
    // eslint-disable-next-line require-atomic-updates
    tabData.value[0].pitPositionListDone = _pitPositionListDone
  }

  const clearSkeletonList = (products) => {
    return products.filter(item => {
      return item._slotType !== PIT_DATA_TYPE.skeletonCard
    })
  }

  const exposeCallBackForRefresh = (exposeNum) => {
    if (!isRefreshTypeAuto.value) return
    if (activeIndex.value !== 0) return
    if (exposeNum >= refreshNum.value) {
      isRefreshDone.value = isRefreshDone.value + 1
      cardRefreshPage.value = 1
    }
  }

  const isLoadRefreshData = computed(() => isRefreshDone.value && activeIndex.value === 0)
  
  // 点击刷新卡片类型
  const needAddPage = computed(() => isRefreshTypeCard.value && isLoadRefreshData.value && cardRefreshPage.value)
  // 自动刷新类型(需要重置页码)
  const needResetPage = computed(() => isRefreshTypeAuto.value && isLoadRefreshData.value && cardRefreshPage.value)

  // 刷新前已曝光商品的 goodsId 集合
  let exposedGoodsId = ''
  const getExposeGoodsId = (tabItem) => {
    if (!isClickedRefresh && !needResetPage.value) return
    if (exposedGoodsId) return exposedGoodsId
    const { products } = tabItem
    let prevProducts = isClickedRefresh ? products.slice(0, refreshNum.value) : products
    prevProducts = prevProducts.filter(item => !item._slotType) || []
    const goodsIdMap = prevProducts.map(({ goods_id }) => goods_id)
    exposedGoodsId = goodsIdMap.join(',')
    return exposedGoodsId
  }

  watch(() => goodsReady.value, val => {
    if (val) {
      isRefreshDone.value = 0
      isClickedRefresh = false
      exposedGoodsId = ''
    }
  })

  return {
    isRefreshTypeAuto,
    clearSkeletonList,
    cardRefreshPage,
    isRefreshTypeCard,
    isRefreshDone,
    pitRefreshCard,
    refreshClick,
    exposeCallBackForRefresh,
    loc: refreshNum.value,
    refreshTexts: texts,

    isLoadRefreshData,
    needAddPage,
    needResetPage,
    getExposeGoodsId
  }
}
