<template>
  <component
    :is="useAnimation ? 'transition' : 'div'" 
    name="fade-slide"
    :appear="true"
  >
    <div 
      v-if="item.goods_id"
      :key="item.goods_id"
      class="recommend-product-card"
      style="margin-bottom: 0.16rem;"
    >
      <NewProductItem 
        :ref="`goodsItem_${index}`"
        :key="`${item.goods_id}-${index}`"
        :config="productItemConfig"
        :item="item"
        :index="index"
        :lcp="landing && (index === 0 || item.lazyClose)"
        :language="language"
        :constant-data="constantData"
        @mounted="$emit('cardMounted', $event, item)"
        @openQuickAdd="handleClickAddCart"
        @clickItem="handleClickItem"
        @longClick="$emit('longClick', $event)"
        @clickSearchFilter="$emit('clickSearchFilter', $event)"
      >
        <template #goodsMain>
          <div class="recommend-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.72791 0.418793C5.42396 -0.14489 6.69473 -0.136993 7.39051 0.427029C7.62358 0.615965 7.88293 0.782558 8.16492 0.899322C8.46464 1.02343 8.78885 1.09079 9.10982 1.12112C9.99856 1.20511 10.8797 2.10411 10.9676 2.99218C10.9977 3.29656 11.0633 3.60418 11.1824 3.8916C11.3094 4.1981 11.4968 4.48066 11.7086 4.73323C12.2792 5.41385 12.2732 6.65614 11.701 7.33546C11.4919 7.58366 11.3073 7.8613 11.1824 8.16283C11.0575 8.46436 10.9918 8.79074 10.9643 9.11335C10.8891 9.99695 10.0209 10.8798 9.13707 10.9563C8.80638 10.985 8.47201 11.0521 8.16492 11.1792C7.86153 11.3049 7.58361 11.4896 7.33624 11.6981C6.66258 12.266 5.44125 12.2855 4.77123 11.7133C4.50662 11.4874 4.21063 11.287 3.89219 11.1551C3.60717 11.0371 3.30064 10.9725 2.99681 10.9433C2.11232 10.8581 1.22354 9.98805 1.14536 9.10322C1.11636 8.77502 1.04926 8.44343 0.922996 8.1387C0.802523 7.84796 0.627751 7.58062 0.429717 7.34115C-0.136979 6.65586 -0.147468 5.40954 0.423801 4.72806C0.634806 4.47635 0.821248 4.19541 0.947136 3.8916C1.06577 3.60528 1.13099 3.29778 1.16079 2.99303C1.24764 2.10478 2.13153 1.20786 3.01989 1.11897C3.33206 1.08774 3.6474 1.02067 3.94047 0.899322C4.22644 0.780908 4.49038 0.611155 4.72791 0.418793Z"
                fill="#CC6E00"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.43359 8.51647H8.35359C8.84359 8.04064 9.33359 6.13397 9.33359 5.65814C9.33359 5.18231 8.96356 5.15373 8.75742 5.08647H6.88359C6.88359 5.08647 7.61015 2.7998 6.63015 2.7998C5.16015 2.7998 5.90359 4.27605 4.43359 5.22939V8.51647Z"
                fill="white"
              />
              <rect
                x="2.80078"
                y="5.25"
                width="0.816667"
                height="3.26667"
                fill="white"
              />
            </svg>
            <p class="text-info">
              {{ item._clickTriggerRecTitlelabelLang || 'Recommended by views' }}
            </p>
          </div>
        </template>
      </NewProductItem>
    </div>
  </component>
</template>

<script>
import { defineComponent } from 'vue'
// components
import NewProductItem from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'

export default defineComponent({
  name: 'ProductListItem',
  components: {
    NewProductItem,
  },
  props: {
    useAnimation: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => ({})
    },
    productItemConfig: {
      type: Object,
      default: () => ({})
    },
    landing: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    handleClickItem(payload) {
      this.$emit('click-item', payload)
    },
    handleClickAddCart(event) {
      this.$emit('open-quick-add', event)
    },
  }
})
</script>
<style lang="less">

/* 进入的起始状态 */
/* stylelint-disable-next-line selector-class-pattern */
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all 0.25s;
}
/* stylelint-disable-next-line selector-class-pattern */
.fade-slide-enter-from, .fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-120px); /* 根据需要调整 */
}

.recommend-product-card {
  --product-card__bottom-wrapper: 0.16rem 0.213rem 0 0.213rem;
  // animation: fadeIn 3s; // 动画声明参见base/animate.less 文件声明
  position: relative;
  z-index: 0; // 解决动画从上到下出来时遮盖上面部分商卡的问题
}
.recommend-info {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(204, 110, 0, 0.20) 100%);
  color: #CC6E00;
  font-family: "SF UI Display";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  padding: 6/37.5rem 8/37.5rem;
  >svg {
    min-width: 12px;
    margin-right: 4/37.5rem;
  }
  .text-info {
    .line-camp(2);
    line-height: 1;
  }
}
</style>
