import { computed, watch } from 'vue'
import { useMapStateV2, useMapGetters } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
// import { getConstantData } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/SurveyQuestion/index.js'

const isInteger = (value) => {
  return /^[+]{0,1}(\d+)$/.test(value)
}

const checkIsModStore = (product) => {
  // 一键购不允许变成时尚店铺
  return product.storeInfo && product.storeInfo.isModStore && product.storeInfo.title && !product.oneClickPay
}

const checkIsTrendContent = (product) => {
  // 一键购不允许变成趋势词内容体
  return product.deliveryInfo && product.deliveryInfo.trend_word_id && !product.oneClickPay
}

// 貌似无法动态 slot 名称, #[TREND_SLOT_TYPE] 不生效？
export const TREND_SLOT_TYPE = 'trendProductItem'

export const useTrendProductItem = () => {
  const { product_detail_abt_info } = useMapGetters('productDetail', ['product_detail_abt_info'])
  // ymal的X坑位后的第1个时尚店铺商品，包装成时尚店铺内容体的样式
  const ymalStoreIndex = computed(() => product_detail_abt_info.value?.ymaltrends?.p?.ymalstore)
  // ymal的X坑位后的第1个趋势商品，包装成时尚店铺内容体的样式
  const ymalTrendsIndex = computed(() => product_detail_abt_info.value?.ymaltrends?.p?.ymaltrends)

  const { goodsReady } = useMapStateV2('newProductDetail/common', ['goodsReady'])

  // 上一次总索引
  let prevTotalIndex = 0

  // 总索引
  let totalIndex = 0

  // 已经设置 ymal store slot
  let isSetYmalStoreSlot = false

  // ymal store 所在索引
  let currentYmalStoreIndex = 0

  // 已经设置 ymal trends slot
  let isSetYmalTrendsSlot = false

  const reset = () => {
    prevTotalIndex = 0
    totalIndex = 0
    isSetYmalStoreSlot = false
    currentYmalStoreIndex = 0
    isSetYmalTrendsSlot = false
  }

  const handleTrendProductSlotData = (products = []) => {
    if (isSetYmalStoreSlot && isSetYmalTrendsSlot) return products

    const isIntegerForStore = isInteger(ymalStoreIndex.value)
    const isIntegerForTrends = isInteger(ymalTrendsIndex.value)
    if (!isIntegerForStore && !isIntegerForTrends) return products

    prevTotalIndex = totalIndex
    totalIndex = products.length 
    if (totalIndex < +ymalStoreIndex.value && totalIndex < +ymalTrendsIndex.value) return products

    return products.reduce((memo, item, idx) => {
      if (
        isIntegerForStore &&
        !isSetYmalStoreSlot &&
        +ymalStoreIndex.value <= totalIndex &&
        idx >= +ymalStoreIndex.value - prevTotalIndex - 1 &&
        idx >= +ymalStoreIndex.value
      ) {
        const isModStore = checkIsModStore(item, idx)
        if (isModStore) {
          item = {
            ...item,
            isFashionStore: true,
            _slotType: TREND_SLOT_TYPE
          }
          isSetYmalStoreSlot = true
          currentYmalStoreIndex = idx
        }
      }

      let currentYmalTrendsIndex = 0

      if (+ymalStoreIndex.value === +ymalTrendsIndex.value && isSetYmalStoreSlot) {
        currentYmalTrendsIndex = currentYmalStoreIndex + 1
      } else {
        currentYmalTrendsIndex = idx
      }

      if (
        isIntegerForTrends &&
        !isSetYmalTrendsSlot &&
        +ymalTrendsIndex.value <= totalIndex &&
        currentYmalTrendsIndex >= +ymalTrendsIndex.value - prevTotalIndex - 1 &&
        currentYmalTrendsIndex >= +ymalTrendsIndex.value
      ) {
        const isTrendContent = checkIsTrendContent(item)
        if (isTrendContent) {
          item = {
            ...item,
            isTrendContent: true,
            _slotType: TREND_SLOT_TYPE
          }
          isSetYmalTrendsSlot = true
        }
      }

      memo.push(item)
      return memo
    }, [])
  }

  // const { constantData } = getConstantData()

  watch(() => goodsReady.value, val => {
    if (val) reset()
  })

  return {
    handleTrendProductSlotData,
    reset,
  }
}
