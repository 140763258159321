import { getBottomRecommendDataApi } from '../../apis.js'
import {
    useMapGetters,
    useMapStateV2,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import {
    ERecommendScene,
} from '../utils.js'
import { unref, ref } from 'vue'

export const useBaseRecommendBottom = ({
  // 单次请求数量
  REC_LIMIT = 30,
} = {}) => {

  const inited = ref(false)
  const firstLoadedSuccess = ref(false)

  const tabData = ref([])
  const activeIndex = ref(0)
  // 进入容错会显示单tab模式
  const showTab = ref(false)

  const { goods_id, goods_sn } = useMapGetters(
    'productDetail/common',
    ['goods_id', 'goods_sn']
  )

  const { attribute, mainAttribute } =
  useMapStateV2('newProductDetail/common', [
    'attribute',
    'mainAttribute',
  ]) // 旧数据流 todo_del

  const { cat_id } = useMapStateV2('productDetail/coldModules/productInfo', [
    'cat_id'
  ])

  const { shop_rec_product_ids } = useMapGetters('productDetail/Recommend', [
    'shop_rec_product_ids',
  ])
  
  async function fetchBottomRecommendData({
    page = 1,
    recommendScene = ERecommendScene.allCate,
    filter_cate_id,
    exposedGoodsId,
  } = {}, {
    isUseAddCartParams,
    isLoadRefreshData,
    isUseAddBoardParams,
    pageClickEvent,
  }) {
    // // test random error
    // function getRandomFloat(min, max) {
    //   return Math.random() * (max - min) + min
    // }
    // if (getRandomFloat(1, 10) > 5) {
    //   console.error('test error')
    //   throw new Error('test error')
    // }
    const res = await getBottomRecommendDataApi({
      goods_id: unref(goods_id),
      goodsSn: unref(goods_sn),
      cat_id: unref(cat_id),
      limit: REC_LIMIT,
      attribute: unref(mainAttribute) || unref(attribute),
      shop_rec_product_ids: unref(shop_rec_product_ids),
      page,
      recommendScene,
      filter_cate_id,
      isAddCart: unref(isUseAddCartParams) ? '1' : '0',
      refresh: unref(isLoadRefreshData) ? '1' : '0',
      featureKey: 'detailsRecommend', // 底部推荐特征值
      addToBoard: unref(isUseAddBoardParams) ? '1' : '0',
      pageClickEvent,
      isDetailYmal: '1',
      exposedGoodsId,
    })
    if (res?.code != '0') throw res // 服务器异常非0
    return res
  }

  return {
    goods_id,

    inited,
    firstLoadedSuccess,
    tabData,
    activeIndex,
    showTab,

    fetchBottomRecommendData,
  }
}
