<template>
  <div
    class="delivery-label__wrap"
  >
    <div
      class="delivery-label__content"
      :class="{ 'delivery-label__quality-content': trendType === 'qualityStore' }"
    >
      <img 
        v-if="iconLink" 
        ref="iconImg"
        class="delivery-label-img"
        :src="iconLink" 
        :style="{width: iconWidth || 'auto'}"
      />
      <div 
        v-else 
        class="delivery-label-fallback"
      >  
        <span
          
          class="delivery-label-text"
        >
          {{ language.SHEIN_KEY_PWA_26897 }}
        </span>
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' fill='none' viewBox='0 0 5 14'%3E%3Cpath id='Rectangle 34624868' fill='%239462FF' d='M3.424 11.03 5 0H0v14a3.46 3.46 0 0 0 3.424-2.97Z'/%3E%3C/svg%3E"
          class="delivery-label__trapezoid"
          :class="{ 'delivery-label__trapezoid-ar': constantData.GB_cssRight }"
        />
      </div>

      <div class="delivery-label-title">
        <template v-if="['fashionStore', 'qualityStore'].includes(trendType)">
          <sui_icon_me_shop_15px
            size="12" 
            :color="trendType === 'qualityStore' ? '#2d261f' : '#9462FF'"
            :style="{ margin: constantData.GB_cssRight ? '0 0 0 0.05333rem' : '0 0.05333rem 0 0' }"
            :is-rotate="constantData.GB_cssRight"
          />
          <span
            ref="titleText"
            class="delivery-label-title__text"
          >
            {{ item.storeInfo?.title || '' }}
          </span>
        </template>
        <span
          v-else
          ref="titleText"
          class="delivery-label-title__text"
        >
          #{{ getWordTitle(item) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { sui_icon_me_shop_15px } from '@shein-aidc/icon-vue3'
import ICON_MAP from './config.js'

function getIOSVersion() {
  var ua = navigator.userAgent
  var isSafari = /^((?!chrome|android).)*safari/i.test(ua)
  if (isSafari) {
    var versionMatch = ua.match(/Version\/([0-9]+)\./)
    if (versionMatch) {
      return parseInt(versionMatch[1], 10)
    }
  }
  return false
}

let iosVersion = ''

export default defineComponent({
  name: 'TrendsLabel',
  components: {
    sui_icon_me_shop_15px,
  },
  props: {
    constantData: {
      type: Object,
      default: (() => {})
    },
    item: {
      type: Object,
      default: (() => {})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    trendType: {
      type: String,
      default: ''
    },
    imgIcon: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    iconLink() {
      if (this.trendType === 'qualityStore') {
        // return this.imgUrl || this.item?.deliveryInfo?.img || this.item?.storeInfo?.trendIcon?.imgUrl || ''
        const imgUrl = this.imgIcon?.imgUrl || this.item?.deliveryInfo?.img || this.item?.storeInfo?.trendIcon?.imgUrl || ''
        return imgUrl
      } else {
        // 兜底 
        const backupKey = typeof window !== 'undefined' ? gbCommonInfo?.appLanguage : ''
        return ICON_MAP[this.constantData.appLanguage ?? backupKey]?.imgUrl || ''
      }
    },
    iconWidth() {
      const imgWidth = this.imgIcon?.width || this.item?.deliveryInfo?.imgWidth || this.item?.storeInfo?.trendIcon?.width
      if (imgWidth) return (imgWidth / 37.5).toFixed(2) + 'rem'
      // 兜底 
      const backupKey = typeof window !== 'undefined' ? gbCommonInfo?.appLanguage : ''
      if (this.trendType === 'qualityStore') {
        const tag = `${this.constantData.lang}_${this.constantData.appLanguage ?? backupKey}`
        if (tag === 'mx_es') {
          return 92 // '//shein.ltwebstatic.com/svgicons/2024/11/28/1732766582296553400.svg'的宽度
        }
      }
      const iconWidth = ICON_MAP[this.constantData.appLanguage ?? backupKey]?.imgWidth || ''
      // 取2位小数
      return iconWidth ? (iconWidth / 37.5).toFixed(2) + 'rem' : ''
    },
  },
  mounted() {
    nextTick(() => {
      iosVersion = getIOSVersion()
      // 低端机需要强制渲染下 否则图片无法显示
      if (iosVersion && iosVersion < 14 && window?.IntersectionObserver) {
        const imgEl = this.$refs.iconImg
        // 当imgEl进入视窗后设置minWidth
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            imgEl.style.minWidth = this.iconWidth
            observer.disconnect()
          }
        })
        observer.observe(imgEl)
      }
    })
  },
  methods: {
    getWordTitle(item) {
      // 剔除趋势词开头的#号
      if (item.bffGoodsInfo && item.deliveryInfo?.display_title) {
        return item.deliveryInfo.display_title.replace(/^#/, '')
      } else {
        return item.deliveryInfo?.display_title || ''
      }
    }
  },
})
</script>

<style lang="less">
.delivery-label__wrap {
  position: relative;
  width: 100%;
  height: 0.3733rem;
  margin-top: 0.16rem;
}
.delivery-label__content {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  width: fit-content;
  color: #9462ff;
  background-color: #F4EFFF;
  border-radius: 2px;
  // 隐藏兜底背景色溢出
  overflow: hidden;
  font-size: 0.2933rem;
  height: .3733rem;

  .delivery-label-title {
    padding: 0.02667rem 0.05333rem;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 100%;
    line-height: 100%;
    overflow-x: hidden;
    &__text {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      unicode-bidi: plaintext;
      overflow: hidden;
      width: 100%;
      line-height: 1.2;
      height: 100%;
      display: inline-block;
    }
  }
  .delivery-label-fallback {
    position: relative;
    display: flex;
    align-items: center;
    height: .3733rem;
    line-height: .3733rem;
    font-size: .2933rem;
    font-weight: 600;
    color: #fff;
    background-color: #9462ff;
    margin-right: .1333rem;
    
    .delivery-label-text {
      padding: 1px 0 1px 3px;
      height: 100%;
    }
  }
  .delivery-label-img {
    height: 100%;
    width: auto;
    flex-basis: 0;
  }
  .delivery-label__trapezoid {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(90%);
    font-size: 0;
    width: fit-content;
  }
  .delivery-label__trapezoid-ar {
    transform: translateX(90%) rotateY(180deg);
  }
}
.delivery-label__quality-content {
  background-color: rgba(255, 165, 71, 0.20);
  .delivery-label-title__text {
    color: #342A21;
  }
}
</style>
