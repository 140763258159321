<template>
  <div
    class="recommend-v3__tab"
  >
    <div
      ref="RecommendTabRef"
      class="s-swiper-container"
      :da-expose-code="componentsCode"
    >
      <swiper-container
        ref="RecommendTabWrapperRef"
        init="false"
        class="s-swiper-wrapper"
      >
        <swiper-slide
          v-for="(item, index) in tabData"
          :key="`${item.id}_${index}`"
          ref="RecommendTabItemRef"
          class="recommend-v3__tab-item"
          :class="{
            'active': activeIndex === index
          }"
          :data-index="index"
          :data-tab-id="item.id"
          :data-tab-nm="item.name"
          :da-event-expose="analysis.tabDAExposeEventId"
          :aria-label="item.name"
          :aria-selected="activeIndex === index ? 'true' : 'false'"
          @click="onTabItemClick({ item, index }, $event)"
        >
          {{ item.name }}
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>
<script setup name="TabUI">
import { onMounted, onUnmounted, ref, unref, defineProps, nextTick } from 'vue'
import { register } from 'swiper/element'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
const daEventExpose = daEventCenter.getExposeInstance()
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()
const emit = defineEmits(['onTabClick'])

defineExpose({
  reload,
})

const props = defineProps({
  tabData: {
    type: Array,
    default: () => [],
    required: true,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  analysis: {
    type: Object,
    default: () => ({
      tabDAExposeEventId: '',
    }),
  },
})

const componentsCode = ref(`detail_rec_bottom_tab_${new Date().getTime()}`) // 埋点用的唯一标识

// swiper相关：
const RecommendTabRef = ref(null)
const RecommendTabItemRef = ref(null)
const RecommendTabWrapperRef = ref(null)
const swiper = ref(null)

function initSwiper() {

  const swiperEl = RecommendTabWrapperRef?.value
  if (!swiperEl) return

  const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
  const swiperParams = {
    dir: GB_cssRight ? 'rtl' : 'ltr',
    direction: 'horizontal',
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 0,
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左
    centeredSlidesBounds: true, // 第一张和最后一张贴合边缘
    slideToClickedSlide: true,
  }
  Object.assign(swiperEl, swiperParams)
  swiperEl.initialize()
  swiper.value = swiperEl.swiper

  setTimeout(() => {
    // tab强制滚动到第一个，部分语言（kr）
    swiper.value.slideTo(0, 0)
  }, 200)

  const { tabDAExposeEventId } = props.analysis
  if (daEventExpose && tabDAExposeEventId) {
  // 声明式曝光埋点
    nextTick(() => {
      daEventExpose.subscribe({
        keycode: `${unref(componentsCode)}\`${tabDAExposeEventId}`,
      })
    })
  }
}

function reload() {
  // 重制曝光
  daEventExpose.reset(unref(componentsCode))
  // 滚动到首个tab
  swiper?.value?.slideTo?.(0, 0)
}

onMounted(() => {
  // 重制曝光
  daEventExpose.reset(unref(componentsCode))
  initSwiper()
})

onUnmounted(() => {
  swiper.value?.destroy()
  daEventExpose?.remove?.(unref(componentsCode))
})


function onTabItemClick({ item, index }, event){
  emit('onTabClick', { preIndex: props.activeIndex.value, item, event, index })
}

// watch(() => props.activeIndex, (index) => {
//   const target = RecommendTabRef?.value?.children[index]
//   // if (!target) return
//   // easeMove(target, index)
// })

// const easeMove = (target, index) => {
//   const container = RecommendTabRef?.value
//   if (!container) return
//   const { right: cRight } = container.getBoundingClientRect()
//   const { left, right } = target.getBoundingClientRect()

//   if (left < 0 || right > cRight) {
//     nextTick(() => {
//       swiper?.value?.slideTo(index)
//     })
//   }
// }
</script>
<style lang="less" scoped>
.recommend-v3__tab {
    position: relative;
    z-index: 8;
    /* stylelint-disable-line declaration-property-value-blacklist */
    padding: 0 .32rem;
    overflow-y: hidden;
    white-space: nowrap;
    background: #fff;
}

.recommend-v3__tab-item {
    display: inline-block;
    position: relative;
    .font-dpr(24px);
    color: #999;
    width: auto !important;
    /* stylelint-disable-line declaration-no-important */
    padding: 0.32rem 0;
    padding-left: 0.12rem;
    padding-right: 0.52rem;

    &.active {
        color: @sui_color_main;
        font-weight: bold;
    }

    &:last-child {
        padding-right: 0.10rem
    }

    &:first-child {
        padding-left: 0
    }
}
</style>
