import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { getFindSimilarDataApi } from '../apis.js'
import { computed, ref } from 'vue'
import { useMapGetters, useMapStateV2 } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'

export const useSimilarPrefix = (handleFormatProductsData) => {
  const { product_detail_abt_info } = useMapGetters('productDetail', ['product_detail_abt_info'])
  const { goods_id, is_spu_sold_out } = useMapGetters('productDetail/common', ['goods_id', 'is_spu_sold_out'])
  const { cat_id } = useMapStateV2('productDetail/coldModules/productInfo', [
    'cat_id'
  ])
  const shouldLoadFindSimilarData = computed(() => {
    return is_spu_sold_out.value && product_detail_abt_info.value?.allsoldout?.p?.allsoldout === 'ymalsimilar'
  })
  const findSimilarProducts = ref([])
  const fetchFindSimilarData = async () => {
    if (!shouldLoadFindSimilarData.value) return
    const abtInfo = await getUserAbtData()
    const { similaritemsnew } = abtInfo || {}
    const matchResult = similaritemsnew?.param?.match(/rule_id=([^&]+)/)
    const rule_id = matchResult && matchResult.length >= 2 ? matchResult[1] || '' : ''
  
    const res = await getFindSimilarDataApi({
      goods_list: `${goods_id.value}_${cat_id.value}`,
      limit: 10,
      page: 1,
      rule_id
    })
    const similarInfo = +res?.code === 0 ? res?.info : null
    findSimilarProducts.value = handleFormatProductsData(similarInfo?.products || [])
    return res
  }

  const filterSimlarProductsInYmal = (products = [], index) => {
    if (index !== 0) return products
    if (!shouldLoadFindSimilarData.value || !findSimilarProducts.value?.length) return products
    // 过滤相同商品
    const findSimilarProductsGoodsIds = findSimilarProducts.value.map(item => item.goods_id)
    products = products.filter(product => !findSimilarProductsGoodsIds.includes(product.goods_id)) 
    return products
  }

  const prefixSimlarDataToYmal = (products = []) => {
    if (!shouldLoadFindSimilarData.value || !findSimilarProducts.value?.length) return products
    products = filterSimlarProductsInYmal(products)
    return [...findSimilarProducts.value, ...products]
  }

  return {
    fetchFindSimilarData,
    prefixSimlarDataToYmal,
    filterSimlarProductsInYmal
  }
}
