import {
    useMapGetters,
    useMapState,
    useMapStateV2,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import {
    useProductCardUtilsWithSetup,
} from 'public/src/pages/goods_detail_v2/utils/recommend.js'
import {
    watch,
    onUnmounted,
    unref,
} from 'vue'
import {
    insertPitDataToTab,
    PIT_DATA_TYPE,
    getPitPositionListByTabItem,
} from 'public/src/pages/goods_detail_v2/innerComponents/recommend/RecommendBottom/insertPit.js'

export const useOutfitInYmal = ({
  tabData,
  inited,
}) => {

  const { outfits_info } = useMapGetters('productDetail/MainPicture', [
    'outfits_info'
  ])
    
  const { complianceTipsMode, complianceModeDe, isHitComplianceMode, productItemsLanguage } = useMapGetters('newProductDetail/common', [
    'complianceTipsMode',
    'complianceModeDe',
    'isHitComplianceMode',
    'productItemsLanguage'
  ])
  const { isPaidUser } = useMapGetters('newProductDetail', [
    'isPaidUser'
  ])
  const { product_detail_abt_info } = useMapGetters('productDetail', [
    'product_detail_abt_info'
  ])

  const { newCompanionInfo } = useMapState('asyncModules', ['newCompanionInfo'])

  const { new_companion_info,
    is_hit_compliance_mode,
    fs_abt,
    language_v2,
  } = useMapGetters('productDetail/common', [
    'new_companion_info',
    'is_hit_compliance_mode',
    'fs_abt',
    'language_v2',
  ])

  const { goodsTransformationWithSetup } = useProductCardUtilsWithSetup()

  const { show_outfit_ymal } = useMapStateV2('productDetail/MainPicture', [
    'show_outfit_ymal'
  ])
  


  let _unWatchFn = null
  const waitData = () => {
    return new Promise((resolve) => {
      if (newCompanionInfo?.value?._inited) return resolve(true)
      _unWatchFn = watch(() => newCompanionInfo.value._inited, () => {
        resolve(true)
      },
      {
        once: true,
      })

      // console.log('waitData', _unWatchFn)
    })
  }

  let _unWatchInitFn = null
  const awaitInited = () => {
    return new Promise((resolve) => {
      if (inited.value) return resolve(true)
      _unWatchInitFn = watch(() => inited.value, (val) => {
        if (val) {
          _unWatchInitFn()
          resolve(true)
        }
      })
    })
  }

  const _unLoad = () => {
    typeof _unWatchFn === 'function' && _unWatchFn()
    typeof _unWatchInitFn === 'function' && _unWatchInitFn()
  }

  onUnmounted(() => {
    _unLoad()
  })

  const getOutfitInYmalInfo = () => {
    // 新搭配底部推荐位插坑数据

    const { newoutfit } = product_detail_abt_info.value || {}
    if (newoutfit?.p?.newoutfit !== 'addbaginymal') return

    const hasOutfitData = outfits_info.value.isHasOutfits && !!outfits_info.value.gtlInfo
    if (!hasOutfitData) return

    const { insertedYmalInfo, productNewCompanionList } = new_companion_info.value || {}
    const { insertedYmalProductInfos = [], uniqueGoodsNum } = insertedYmalInfo || {}
    if (!insertedYmalProductInfos?.length) return

    const insertType = newoutfit?.p?.inymaltype || 'photo' //'items'
    // const insertType = 'items'

    const insertOrder = +newoutfit?.p?.inymalnum || 1
    // const insertOrder = 33 // todo

    const { themeId, lookType, productInfoList, detailSeriesNo = '-' } = outfits_info.value.gtlInfo || {}
    const firstAnalysisData = { // 曝光的属性都按第一个套装报
      goods_num: productInfoList.length || 0,
      theme_id: themeId,
      look_type: lookType,
      series_no: detailSeriesNo,
      location: 'outfitymal'
    }

    const insertOutfit = {
      _slotType: PIT_DATA_TYPE.outfitYmal,
      _pitIndex: insertOrder - 1,
      insertOrder: insertOrder - 1,
      isOutfit: true,
      insertType,
      outfits_info: outfits_info.value,
      is_hit_compliance_mode: is_hit_compliance_mode.value,
      fs_abt: fs_abt.value,
      language: language_v2.value,
      firstAnalysisData,
    }
    if (insertType !== 'items') {
      return insertOutfit
    }

    // 多个套装
    const useBlackColorPrice = complianceTipsMode.value || complianceModeDe.value
    const ProductCardPriceSimpleComponent = isHitComplianceMode.value && !useBlackColorPrice ? `ProductCardPriceSimpleSuggested` : `ProductCardPriceSimple`
    const priceCardConfig = {
      direction: 'column',
      showS3VipPriceOnSale: fs_abt.value?.EstimatedPrice?.p?.S3newprice === 'new', // 主价格显示会员 s3 价格
      hidePriceBottomLabel: true
    }
    if(useBlackColorPrice) {
      priceCardConfig.priceColor = '#000'
    }

    const allGtlProducts = insertedYmalProductInfos.map(d => {
      const { detailCompanionDataType, detailSeriesNo, productInfo } = d
      const item = goodsTransformationWithSetup(productInfo)
      if (+detailCompanionDataType == 0) {
        const groupIndex = productNewCompanionList.findIndex(dd => dd.detailSeriesNo == detailSeriesNo)
        item.swiperIndex = groupIndex
        item.companionItem = productNewCompanionList[groupIndex]
      } else {
        const groupIndex = productNewCompanionList.findIndex(dd => dd.detailCompanionDataType == detailCompanionDataType)
        item.swiperIndex = groupIndex
        item.companionItem = productNewCompanionList[groupIndex]
      }

      return item
    })

    return {
      ...insertOutfit,
      allGtlProducts,
      priceCardConfig,
      ProductCardPriceSimpleComponent,
      isPaidUser: isPaidUser.value,
      productItemsLanguage: productItemsLanguage.value,
      uniqueGoodsNum,
    }
  }


  const insterOutfitYmal = async () => {
    if (!unref(show_outfit_ymal)) return

    typeof _unWatchFn === 'function' && _unWatchFn()
    await waitData() // 等待数据源落地
    await awaitInited() // 等待底部推荐位初始化完成

    const tabItem = tabData.value?.[0]

    if (!tabItem) return
    const isHasInster = getPitPositionListByTabItem(tabItem)?.find(d => d._slotType === PIT_DATA_TYPE.outfitYmal)
    if (isHasInster) return


    const outfitInYmalInfo = getOutfitInYmalInfo()
    // console.log('outfitInYmalInfo', outfitInYmalInfo)
    if (!outfitInYmalInfo) return
    insertPitDataToTab(tabItem, outfitInYmalInfo) 
  }


  watch(show_outfit_ymal, async () => {
    insterOutfitYmal()
  }, {
    immediate: true,
  })

  return {
    insterOutfitYmal,
  }
}

