<template>
  <ListDataController
    ref="ListDataControllerRef"
    :products="tabItem.products"
    v-bind="listBindData"
    :box-bind-data="getBoxBindData(tabItem)"
    :itemLanguage="itemLanguage"
    :load-space="600"
    :start-page="tabItem.page + 1"
    :load-data="$event => loadNextPageData($event, tabIndex)"
    :isUseNewWaterFall="true"
    :dynamicItems="tabItem.dynamicItems"
    :deleteItemsFlag="tabItem.deleteItemsFlag"
    :needAddPage="needAddPage"
    :needResetPage="needResetPage"
    :is-ymal-tab="true"
    @update:deleteItemsFlag="updateProductsDeleteFlag"
    @click-item="onClickItem"
    @open-quick-add="onOpenQuickAddClickRec"
  >
    <!-- 点推推荐商品 -->
    <template #clickRec="{ item, index: itemIndex }">
      <RecommendProductListItem
        :item="item"
        :index="itemIndex"
        :use-animation="!item._click_rec_mounted"
        :constantData="constantData"
        :language="itemLanguage"
        :productItemConfig="listBindData.productItemsConfig"
        @click-item="onClickItem"
        @open-quick-add="onOpenQuickAdd"
        @cardMounted="onClickRecProductItemMounted"
      />
    </template>
    <template 
      #outfitYmal="{ item }"
    >
      <!-- outfit插坑 -->
      <OutfitInYmal 
        :item="item"
        @open-quick-add="onOpenQuickAdd"
        @open-gtl-popup="onOpenGtlPopup"
      />
    </template>
    <template #surveyQuestion>
      <!-- 问卷插坑 -->
      <SurveyQuestion
        v-bind="surveyPit.cSurveyQuestionComBindProps"
        :compStyle="{
          marginBottom: '.16rem',
          width: '4.76rem'
        }"
      />
    </template>
    <template #trendProductItem="{ item, index: idx }">
      <!-- 趋势店铺或者趋势商品 内容体 -->
      <TrendProductItem 
        :item="item"
        :index="idx"
        :productItemConfig="itemConfig"
        :language="itemLanguage"
        :constantData="constantData"
        :mainGoodsId="goods_id"
      />
    </template>
    <template #rankListComponent>
      <!-- 榜单插坑 -->
      <RankListComponent 
        v-bind="rankListPit.rankListComponentBindProps"
        @expose-rank="rankListPit.setExposeRankToSession"
      />
    </template>
    <template #refreshCard>
      <!-- 刷新插坑 -->
      <RefreshCard v-bind="refreshRecPit" />
    </template>
    <template #skeletonCard>
      <!-- 刷新用骨架插坑 -->
      <SkeletonCard />
    </template>
  </ListDataController>
</template>
<script setup>
import { defineProps, ref, computed, inject, nextTick } from 'vue'
import ListDataController from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/ListDataController/index.vue'
import OutfitInYmal from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/OutfitInYmal/index.vue'
import RecommendProductListItem from './click-rec/RecommendProductListItem.vue'
import SurveyQuestion from 'public/src/pages/components/product/carrier_ui/survey-question/index.vue'
import RankListComponent from 'public/src/pages/product_list_v2/components/flow/flow_rank/Container.vue'
import TrendProductItem from 'public/src/pages/goods_detail_v2/innerComponents/recommend/Trend/index.vue'
import RefreshCard from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/RefreshCard/index.vue'
import SkeletonCard from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/SkeletonCard/index.vue'

import {
  useMapActions,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import {
    getBoxBindData,
} from './utils'

const onOpenQuickAdd = inject('onOpenQuickAdd')
const loadNextPageData = inject('loadNextPageData')

const ListDataControllerRef = ref(null)

const props = defineProps({
  tabItem: {
    type: Object,
    default: () => {}
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  listBindData: {
    type: Object,
    default: () => {}
  },
  itemConfig: {
    type: Object,
    default: () => {}
  },
  itemLanguage: {
    type: Object,
    default: () => {}
  },
  goodsId: {
    type: String,
    default: ''
  },
  constantData: {
    type: Object,
    default: () => {}
  },
  needAddPage: {
    type: [Number, Boolean],
    default: false,
  },
  needResetPage: {
    type: [Number, Boolean],
    default: false,
  },

  // 插坑数据
  surveyPit: {
    type: Object,
    default: () => {}
  },
  rankListPit: {
    type: Object,
    default: () => {}
  },
  clickRecPit: {
    type: Object,
    default: () => {}
  },
  refreshRecPit: {
    type: Object,
    default: () => {}
  },
})

const goods_id = computed(() => props.goodsId)


// 打开新搭配弹窗
const { open_get_the_look_new_popup } = useMapActions('productDetail/Recommend', ['open_get_the_look_new_popup'])
function onOpenGtlPopup(swiperIndex) {
  // swiperIndex 点击对应商品时，锚中对应的套装，若有重复，则按照第一个套装算。
  open_get_the_look_new_popup({ initCateTab: '', activeFrom: 'outfitymal', swiperIndex })
}


/**
 * 点推相关 START
 */
const onClickItem = (item) => {
  props.clickRecPit?.handleClickRecAfterClickItem?.(item)
}

// 点推快速加车
const onOpenQuickAddClickRec = (payload) => {
  return props.clickRecPit?.handleClickRecAfterOpenQuickAdd?.(payload, {
    onOpenQuickAdd,
    tabItem: props.tabItem,
  })
}

// 点推商品插入后补全埋点数据
const onClickRecProductItemMounted = (componetInstance, goodItems) => {
  // console.log('componetInstance', componetInstance, goodItems)
  if(componetInstance?.$el?.dataset?.id) return // 已经设置过埋点属性

  componetInstance?.setElAttr?.() // 设置埋点属性在dom上
  const { _position_index, _from_goods_id, _click_goods_position_index } = goodItems || {}

  if(!componetInstance?.$el?.dataset?.detailYmalclickrecGoodsId) {
    componetInstance?.$el.setAttribute('data-detail-ymalclickrec-position', `ymalclickrec_position_${_click_goods_position_index + 1}_${_position_index + 1}`)
    componetInstance?.$el.setAttribute('data-detail-ymalclickrec-goods-id', `ymalclickrec_from_goods_id_${_from_goods_id}`)
  }

  nextTick(() => {
    ListDataControllerRef?.value?.updateListExpose?.()
  })
}

function updateProductsDeleteFlag(value) {
  props.tabItem.deleteItemsFlag = value
}

/**
 * 点推相关 END
 */

const initPage = () => {
  ListDataControllerRef?.value?.initPage?.()
}
defineExpose({
  initPage,
})
</script>
