<template>
  <component
    :is="currComp"
    v-if="currComp"
    :flow-index="index"
    :language="language"
    :flow-ranking-list="finalRankData"
    :shein-club-info="sheinClubInfo"
  />
</template>

<script>
/**
 * @file 列表页插坑榜单、商详场景插坑榜单
 */

import { defineComponent, computed, provide, onActivated, defineAsyncComponent } from 'vue'

import { initFlowRankAnalysis } from './common/flow_rank_analysis.js'
import { adaptOriginRankData } from './common/utils.js'

const FlowHotMod  = defineAsyncComponent(() => import('./flowHotMod/Container.vue'))
const FlowDiscountMod  = defineAsyncComponent(() => import('./flowDiscountMod/Container.vue'))

const compMap = {
  15: FlowHotMod, // 热销榜
  77: FlowHotMod, // 店铺item热销榜
  75: FlowDiscountMod, // 折扣榜
}

export default defineComponent({
  name: 'FlowRankContainer',
  props: {
    flowRankingList: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    abtInfo: {
      type: Object,
      default: () => ({}),
    },
    sheinClubInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const finalRankData = computed(() => {
      const customData = props.flowRankingList?.customData || {}
      if (['detail_ymal', 'cart_recommend'].includes(customData.from) || (customData.from === 'storeItem' && customData.version === 'v2')) {
        return adaptOriginRankData({
          customData,
          language: props.language,
          sheinClubInfo: props.sheinClubInfo,
        })
      }
      return props.flowRankingList
    })

    const emitExposeRank = () => {
      emit('expose-rank', finalRankData.value.contentCarrierId)
    }

    const flowRankAnalysis = initFlowRankAnalysis({
      flowRankingList: finalRankData.value,
      flowIndex: props.index,
      emitExposeRank,
    })

    provide('FLOW_RANK_STATE_TOKEN', {
      language: props.language,
      sheinClubInfo: props.sheinClubInfo,
      flowRankAnalysis,
      rankAbtInfo: props.abtInfo,
    })

    onActivated(() => {
      flowRankAnalysis.resetRankExpose?.()
    })

    const currComp = computed(() => {
      const result = compMap[finalRankData.value?.carrierData?.carrierSubType || '']
      return result || null
    })

    return {
      currComp,
      finalRankData,
    }
  },
})
</script>
