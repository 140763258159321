<template>
  <div>
    <section
      class="detail-shein-info"
    >
      <FooterSheinInfo
        class="j-ga-detail-shein-info"
        :title-required="true"
        arrow="content"
        data-type="product_details_page"
        :analysis-info="{eventCategory: '商品详情页', exposeCode: 'detail'}"
      />
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { BFooterSheinInfo as FooterSheinInfo } from '@shein-aidc/bs-footer-shein-info-mobile'
import { transformImg } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { markPoint } from 'public/src/services/mark/index.js'
export default defineComponent({
  name: 'UsInfo',
  components: {
    FooterSheinInfo,
  },
  props: {
    onloadStatus: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      pageLogoList: [],
      findUs: {
        vk: {
          url: '',
          iconfont: 'icon-vk'
        },
        facebook: {
          url: '',
          iconfont: 'icon-facebook'
        },
        line: {
          url: '',
          iconfont: 'icon-line'
        },
        instagram: {
          url: '',
          iconfont: 'icon-instagram'
        },
        twitter: {
          url: '',
          iconfont: 'icon-tumblr'
        },
        youtube: {
          url: '',
          iconfont: 'icon-youtube'
        },
        pinterest: {
          url: '',
          iconfont: 'icon-pinterest'
        },
        official: {
          url: '',
          iconfont: 'icon-rectangle'
        },
        snapchat: {
          url: '',
          iconfont: 'icon-snapchat'
        }
      }
    }
  },
  watch: {
    onloadStatus: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          // 标识站外才请求
          if (!this.show) return
          this.matchFindUsUrlByLang()
          this.getPayIcon()
        }
      }
    }
  },
  methods: {
    transformImg,
    matchFindUsUrlByLang () {
      const lang = this.lang
      Object.keys(this.findUs).forEach((item) => {
        let url = ''
        switch (item) {
          case 'vk':
            if (lang === 'ru') url = 'https://vk.com/sheinsider'
            break
          case 'facebook':
            url = 'https://www.facebook.com/SheInside/'
            if (['en', 'il', 'ilen', 'hk', 'se', 'sesv', 'nl', 'cl', 'ca', 'cafr', 'eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(lang)) url = 'https://www.facebook.com/SHEIN2019/'
            if (lang === 'us') url = 'https://www.facebook.com/sheinus/'
            if (lang === 'uk') url = 'https://www.facebook.com/sheingb/'
            if (lang === 'in') url = 'https://www.facebook.com/sheinindia/'
            if (lang === 'au') url = 'https://www.facebook.com/SheInAustralia/'
            if (lang === 'ru') url = 'https://www.facebook.com/SheInRussia/'
            if (lang === 'es') url = 'https://www.facebook.com/SheInSpain/'
            if (lang === 'mx') url = 'https://www.facebook.com/sheinmexico/'
            if (lang === 'fr') url = 'https://www.facebook.com/SheInFrance/'
            if (lang === 'de') url = 'https://www.facebook.com/SheInGermany/'
            if (lang === 'it') url = 'https://www.facebook.com/sheinit/'
            if (lang === 'tw') url = 'https://www.facebook.com/sheintaiwan/'
            if (lang === 'th' || lang === 'then') url = 'https://www.facebook.com/SHEINThailand/'
            if (lang === 'vn') url = 'https://www.facebook.com/SHEINVietnam/'
            break
          case 'line':
            if (lang === 'tw') url = 'https://line.me/R/ti/p/%40ciq8183f'
            if (lang === 'th' || lang === 'then') url = 'https://line.me/R/ti/p/%40gej7270t'
            break
          case 'instagram':
            url = 'https://www.instagram.com/sheinofficial/'
            if (['us', 'in', 'it', 'au'].includes(lang)) url = `https://www.instagram.com/shein_${lang}`
            if (lang === 'ru') url = 'https://www.instagram.com/shein_russia/'
            if (lang === 'es') url = 'https://www.instagram.com/shein_spain/'
            if (lang === 'mx') url = 'https://www.instagram.com/shein_mex/'
            if (lang === 'fr') url = 'https://www.instagram.com/sheinfrance_/'
            if (lang === 'uk') url = 'https://www.instagram.com/shein_gb/'
            if (lang === 'de') url = 'https://www.instagram.com/sheingermany/'
            if (lang === 'tw') url = 'https://www.instagram.com/shein_tw/'
            if (lang === 'th' || lang === 'then') url = 'https://www.instagram.com/shein_thailand/'
            if (lang === 'vn') url = 'https://www.instagram.com/shein_vn/'
            break
          case 'twitter':
            url = 'https://twitter.com/SheIn_official'
            if (lang === 'ru') url = 'https://twitter.com/sheinrussia'
            break
          case 'youtube':
            url = 'https://www.youtube.com/user/sheinsidevideo'
            if (lang === 'ru') url = 'https://www.youtube.com/channel/UCX5551ORboU4QXMwoLptYvQ'
            break
          case 'pinterest':
            url = 'https://www.pinterest.com/SHEINofficial/'
            break
          case 'official':
            url = 'http://shein-official.tumblr.com/'
            break
          case 'snapchat':
            url = 'https://www.snapchat.com/add/sheinofficial'
            if (lang === 'ar') url = 'https://www.snapchat.com/add/shein_88'
            break
        }
        this.findUs[item]['url'] = url
      })
    },
    getPayIcon () {
      schttp({
        url: '/api/cart/pageLogo/get',
        params: {
          page_type: 1
        }
      }).then(({ code, info = {} } = {}) => {
        if (code != 0) return
        const { logoList = [] } = info
        this.pageLogoList = logoList
      })
    },
    clickIcon ({ url = '' } = {}) {
      window.setTimeout(() => {
        markPoint('toNextPageClick', 'public')
        location.href = url
      })
    },
    clickAboutUs (url) {
      window.setTimeout(() => {
        markPoint('toNextPageClick', 'public')
        location.href = url
      })
    }
  },
})
</script>

<style lang="less">
.c-detail-usInfo {
    // padding: 1.28rem .64rem 0 .64rem;
    padding: 0 0.64rem;
    text-align: center;
    color: #222;
    background: #f6f6f6;
    h3 {
        line-height: 0.3867rem;
        font-weight: 400;
        .font-dpr(24px);
    }

    .footer-copyright{
        padding-top: 0;
    }

    a {
        text-decoration: none;
    }

    a::after {
        text-decoration: none;
    }

    &__pay-logo {
        margin: 0;

        img {
            width:1.1867rem;
            height:0.7867rem;
            margin-bottom: 0.2133rem;
            margin-left: 0.32rem/*rtl:ignore*/;
        }

        img:first-child {
            margin-left: 0/*rtl:ignore*/;
        }
    }

    &__find-us {
        .clearfix();
        padding: .32rem 0 0.4rem 0;
        margin-bottom: .64rem;
        border-bottom: 0.026rem solid #E5E5E5;
        a {
            display: inline-block;
            padding: 0 .16rem;
            .font-dpr(56px);
            color: #222;
        }
    }

    &__about-me {
        margin-bottom: 0.1067rem;

        a {
            color: #003569;
        }
    }

    &__rights {
        color: #ccc;
    }
}
.detail-shein-info{
    background-color: #f6f6f6;
    padding: 0.32rem;
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
}
</style>
