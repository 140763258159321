
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

export const adaptOriginRankData = ({ customData, language, sheinClubInfo }) => {
  let flowRankingList = null
  const { rankListComponentResult, suggestedSaleType, } = customData || {}
  if (rankListComponentResult && rankListComponentResult.title && rankListComponentResult.products?.length) {
    const goods = rankListComponentResult.products.map(productItem => {
      const goodsInfo = goodsTransformation(productItem, {
        suggestedSaleType,
        language: language || {},
        lang: gbCommonInfo.lang,
        isPaid: !!sheinClubInfo?.isPaid,
      })
      goodsInfo.rank = productItem.rank
      return goodsInfo
    })
    
    flowRankingList = Object.assign({}, rankListComponentResult, {
      version: 'v2',
      customData,
      carrierData: {
        carrierSubType: Number(rankListComponentResult.carrierSubType),
        cateIds: rankListComponentResult.cate_id || '-',
      },
      goods,
      products: null,
    })
  } else {
    flowRankingList = null
  }
  return flowRankingList
}
