<template>
  <div
    class="recommend-bottom"
    data-floor-tab="youMayAlsoLike"
  >
    <div class="recommend-bottom-top">
      <div
        class="recommend-bottom-top_title"
        :class="{
          nottab: !showTab
        }"
      >
        {{ cTitle }}
      </div>
    </div>
    <!-- Tabs -->
    <TabUI
      v-if="showTab"
      ref="TabRef"
      class="recommend-bottom-tab"
      :style="cTabStickyTopStyle"
      :tabData="tabData"
      :activeIndex="activeIndex"
      :analysis="{
        tabDAExposeEventId: '1-6-5-4'
      }"
      @onTabClick="onTabClick"
    />
    <div ref="ListWrapRef">
      <div
        v-if="inited && firstLoadedSuccess"
        class="recommend-bottom-list"
      >
        <template
          v-for="(tab, index) in tabData"
          :key="index"
        >
          <template v-if="tab.isYmalTab">
            <!-- ymal tab -->
            <YmalTabList
              v-show="activeIndex === index"
              ref="ListDataControllerRef"
              :tabItem="tab"
              :itemLanguage="product_items_language"
              :constantData="constantData"
              :needAddPage="needAddPageForRefresh"
              :needResetPage="needResetPageForRefresh"
              :listBindData="{
                closeScrollHandler: activeIndex !== index || tab.faulttolerant,
                isUsePageScroll: true,
                productItemsConfig: ymalItemConfig
              }"
              :surveyPit="{
                cSurveyQuestionComBindProps
              }"
              :rankListPit="{
                rankListComponentBindProps,
                setExposeRankToSession,
              }"
              :clickRecPit="{
                handleClickRecAfterClickItem,
                handleClickRecAfterOpenQuickAdd,
              }"
              :refreshRecPit="{
                refreshClick,
                loc,
                refreshTexts
              }"
            />
          </template>
          <template v-else>
            <!-- 其他商品滚动列表 -->
            <ListDataController
              v-show="activeIndex === index"
              ref="ListDataControllerRef"
              :products="tab.products"
              :product-items-config="otherListItemConfig"
              :load-space="600"
              :box-bind-data="getBoxBindData(tab)"
              :start-page="1"
              :load-data="$event => loadNextPageData($event, index)"
              :item-language="product_items_language"
              :closeScrollHandler="activeIndex !== index || tab.faulttolerant"
              :isUsePageScroll="true"
              @open-quick-add="onOpenQuickAdd"
            />
          </template>
        </template>
      </div>
      <!-- 初始化失败 重新加载页面 -->
      <div
        v-else-if="inited && !firstLoadedSuccess"
        class="recommend-bottom-list error-page"
      >
        <ErrorReloadPage
          @reload="initData"
        />
      </div>
      <!-- 初始化中 -->
      <div
        v-else
        class="recommend-bottom-list"
      >
        <CardListSkeleton />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RecommendBottom',
})
</script>
<script setup>
import {
  ref,
  unref,
  watch,
  // shallowRef,
  computed,
  nextTick,
  provide,
  onUnmounted,
} from 'vue'
import TabUI from './TabUI'
import ListDataController from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/ListDataController/index.vue'
import ErrorReloadPage from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/ErrorReloadPage/index.vue'
import CardListSkeleton from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/CardListSkeleton/index.vue'

import YmalTabList from './YmalTabList.vue'
import { useRefreshRec } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/RecommendBottom/refresh-rec/hooks.js'
import {
  getFormatTabData,
  useAfterAddCartRefresh,
  useTabLastScrollTop,
  useExposeGoodsPool,
  BaseAnalysisInfo,
  getBoxBindData,

  ymalExposureListInstance,

  AsyncTask,
  updateTabItemIdsMap,
  filterRepeatGoods,
  useAfterAddWishRefresh,
  useYmalLoadedAndExpose,
  filterRecommendData,

  useBatheYmal
} from './utils.js'
import { useBaseRecommendBottom } from './hooks/use-base.js'
import {
  useMapGetters,
  useMapStateV2,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import {
  useItemConfig,
  EItemConfigType
} from 'public/src/pages/goods_detail_v2/utils/ProductConfigManager/index.js'
import {
  useProductCardUtilsWithSetup,
  useOpenQuickAddWithProductCart,
  useProductCardInfo,
} from 'public/src/pages/goods_detail_v2/utils/recommend.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useSurveyQuestion } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/SurveyQuestion/index.js'
import { insertPitDataWithNextPage } from './insertPit.js'
import { useYmalClickRec } from './click-rec/click-rec.js'
// trend

import { useTrendProductItem } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/Trend/index.js'
import { useOutfitInYmal } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/components/OutfitInYmal/hook.js'

// 榜单坑位插入 hook
import { useRankTopItem } from '../RankTop'

// similar data 前置插入 ymal
import { useSimilarPrefix } from '../SimilarPrefix/index'
import recommendFilterInstance from 'public/src/pages/goods_detail_v2/utils/recommendFilter.js'

import { useReqActions } from './hooks/use-req-actions.js'

// 合并场景请求的推荐数量为30
const REC_LIMIT = 30

/**
 * 全局状态
 */
 
const {
  inited,
  firstLoadedSuccess,
  tabData,
  activeIndex,
  showTab,
  fetchBottomRecommendData: _fetchBottomRecommendData,
} = useBaseRecommendBottom({
  REC_LIMIT,
})
const { constantData } = useProductCardInfo()

const TabRef = ref(null)
const ListDataControllerRef = ref(null)
const ListWrapRef = ref(null)

const { goods_id, language_v2, product_items_language } = useMapGetters(
  'productDetail/common',
  ['goods_id', 'language_v2', 'product_items_language']
)

const { ymal_rank_info } = useMapStateV2('productDetail/Middle', [
  'ymal_rank_info'
])

const { detailPageOnloadStatus } =
  useMapStateV2('newProductDetail/common', [
    'detailPageOnloadStatus',
  ]) // 旧数据流 todo_del

/**
 * 全局计算属性
 */

const cTitle = computed(
  () => unref(language_v2).SHEIN_KEY_PWA_14989 || 'You May Also Like'
)

const cStickyTop = computed(() => {
  return 2.3
})

const cTabStickyTopStyle = computed(() => {
  return {
    position: 'sticky',
    top: `${cStickyTop.value}rem`,
  }
})


// 其他tab需要去重第一个Tab前30个数据
const cOtherTabProductsFiltersIdsMap = computed(() => {
  const firstTabProducts = tabData?.value[0]?.products || []
  if (!firstTabProducts.length) return
  const RD_LIMIT_BY_FIRST_TAB = 30
  return firstTabProducts.slice(0, RD_LIMIT_BY_FIRST_TAB).reduce((acc, cur) => {
    acc[cur.goods_id] = true
    return acc
  }, {})
})

/**
 * hooks
 */
const { insterOutfitYmal } =  useOutfitInYmal({
  tabData,
  inited,
}) // outfit插坑
/**
 * 加车后刷新实验
 */
const { isUseAddCartParams, isYmalAddBagRefreshing } = useAfterAddCartRefresh({
  initData,
  insterOutfitYmal,
})
const { openQuickAddWithProductCart } = useOpenQuickAddWithProductCart()
const { goodsTransformationWithSetup } = useProductCardUtilsWithSetup()
const {
  setTabScrollTopWithListDom,
  setTabScrollTopAllEmpty,
  setTabScrollTopToLastWithIndex
} = useTabLastScrollTop({
  TabRef,
  ListWrapRef,
  stickyTop: cStickyTop
})

// 商详YMAL新增时尚店铺&趋势内容体(wiki 1537936759)
const {
  handleTrendProductSlotData,
  reset,
} = useTrendProductItem()

const { itemConfig } = useItemConfig({
  name: 'DetailRecommendBottom',
  type: EItemConfigType.Pic2,
  mergeConfig: {
    itemDAEventClickId: '2-3-1',
    itemDAEventExposeId: '2-3-2',
    // 瀑布流方图
    autoCropImage: true,
    useOwnClickAddBagAnalysis: false,
    showFeedback: true, // 展示负反馈
  }
})

const { pushExposeGoodsPoolWithTargets } = useExposeGoodsPool()

// 沉浸式浏览 ymal
useBatheYmal({
  showTab,
  tabRef: TabRef
})

const ymalItemConfig = computed(() => {
  return {
    ...unref(itemConfig),
    exposeCallback: ({ targets } = {}) => {
      pushExposeGoodsPoolWithTargets({ targets, isYmalTab: true })
      ymalExposureListInstance.pushQueen({ targets })
    }
  }
})
const otherListItemConfig = computed(() => {
  return {
    ...unref(itemConfig),
    exposeCallback: ({ targets } = {}) => {
      pushExposeGoodsPoolWithTargets({ targets })
    }
  }
})


/**
 * 插坑相关
 */
const { cSurveyQuestionComBindProps, initSurveyQuestion, insterSurverQuestion } = useSurveyQuestion({
  inited,
  tabData,
})
initSurveyQuestion() // setup阶段就初始化问卷数据，只初始化一次

// 榜单插坑
const { 
  fetchRankComponent, 
  setExposeRankToSession, 
  rankListComponentBindProps, 
  filterRankProductsInYmal,
  insertRankTop,
} = useRankTopItem()

// 主商品收藏后滑动到 ymal
const { isUseAddBoardParams, isYmalAddBoardRefreshing } = useAfterAddWishRefresh({ initData })

// ymal 加载和曝光的状态 
useYmalLoadedAndExpose()

/**
 * 重置数据
 */
function unload() {
  inited.value = false
  firstLoadedSuccess.value = false
  tabData.value = []
  activeIndex.value = 0
  showTab.value = false

  setTabScrollTopAllEmpty()
  // 清除掉异步任务
  AsyncTask.cleanTask()
}

function setInitData({
  data = [],
  initedError = false
}) {
  // 如果请求的数据没有多于两个不展示tab的数据栏
  showTab.value = data.length > 2
  tabData.value = showTab.value ? data : data.slice(0, 1)

  firstLoadedSuccess.value = !initedError
  inited.value = true
  isYmalAddBagRefreshing.value = false
  isYmalAddBoardRefreshing.value = false
}


// ccc负反馈相关数据
const { get_ccc_feed_back } = useMapActions('productDetail/Recommend', ['get_ccc_feed_back'])
const { ccc_feed_back } = useMapStateV2('productDetail/Recommend', ['ccc_feed_back'])

const handleFormatProductsData = (products = []) => {
  const cccFeedback = unref(ccc_feed_back)
  return products?.map(item => {
    const userBehaviorLabel = item.productMaterial?.userBehaviorLabel || null
    const otherMarks = item.productMaterial?.otherMarks || ''
    const newItem = goodsTransformationWithSetup(item)
    if(userBehaviorLabel){
      newItem.userBehaviorLabel = userBehaviorLabel
    }
    if(otherMarks){
      newItem.otherMarks = otherMarks
    }
    if (!cccFeedback) return newItem

    return {
      ...newItem,
      cccFeedback, // 混入ccc负反馈数据
    }
  })
}

const isFirstTab = computed(() => +activeIndex.value === 0)

// 点推相关 START
const {
  initClickRecData,
  handleClickRecAfterClickItem,
  handleClickRecAfterOpenQuickAdd,

} = useYmalClickRec({
  goodsTransformationWithSetup,
  mainGoodsId: goods_id,
  tabData,

  isUseAddCartParams,
  isUseAddBoardParams,
  isFirstTab
})

const {
  pitRefreshCard,
  refreshClick,
  loc,
  exposeCallBackForRefresh,
  refreshTexts,
  clearSkeletonList,

  isRefreshDone,
  isLoadRefreshData,
  needAddPage: needAddPageForRefresh,
  needResetPage: needResetPageForRefresh,
  getExposeGoodsId: getExposeGoodsIdForRefresh
} = useRefreshRec({
  activeIndex,
  tabData,
  loadNextPageData,
  reset,
})

// 前置相似数据
const { 
  fetchFindSimilarData, 
  prefixSimlarDataToYmal, 
  filterSimlarProductsInYmal 
} = useSimilarPrefix(handleFormatProductsData) 

/**
 * 初始化数据
 */
async function initData() {
  unload()
  isYmalAddBagRefreshing.value = isUseAddCartParams.value
  isYmalAddBoardRefreshing.value = isUseAddBoardParams.value
  let infoData
  let _isLoadErr = false

  const checkGoodsId = unref(goods_id)

  const clickRecInfo = await initClickRecData({
    checkGoodsId,
    setInitData,
  })


  if (clickRecInfo) { // 执行点推的初始化逻辑
    const { taskExecute, taskCease } = clickRecInfo
    AsyncTask.addTask({
      taskExecute,
      taskCease,
    })
    return
  }

  console.log('RecommendBottom initData fetchBottomRecommendData')

  try {
    const [res] = await Promise.all(
      [
        fetchBottomRecommendData(), 
        get_ccc_feed_back(),
        fetchFindSimilarData(),
        fetchRankComponent(ymal_rank_info.value) // 同步请求，防止插坑抖动
      ]
    )
    infoData = res?.info || {}
  } catch (e) {
    console.error('initData error', e)
    infoData = {}
    _isLoadErr = true
  }

  if (checkGoodsId !== unref(goods_id)) {
    console.log('RecommendBottom initData goods_id changed', checkGoodsId, unref(goods_id))
    return // 商品id变化不处理
  }

  const {
    categories = [],
    is_faulttolerant,
    products: _sourceProducts = [],
    total,
  } = infoData

  let _tabData = []
  if (categories?.length) {
    _tabData = getFormatTabData(categories)
  } else {
    // 没返回类目数据时，显示单tab
    _tabData = getFormatTabData([{ cat_id: '', cat_name: '' }])
  }

  let products = handleFormatProductsData(_sourceProducts)
  products = prefixSimlarDataToYmal(products)
  products = handleTrendProductSlotData(products)
  
  products = setAnalysisFlag(products, true)

  // 首个tab填入数据
  _tabData[0] = {
    ..._tabData[0],
    products,
    total,
    faulttolerant: is_faulttolerant === '1',
    page: 1,
  }

  pitRefreshCard(_tabData[0])
  exposeCallBackForRefresh(products.length)

  insterSurverQuestion(_tabData[0]) // 问卷插坑
  insertRankTop(_tabData[0]) // 榜单插坑

  // console.log('fetchBottomRecommendData tabData', _tabData)

  updateTabItemIdsMap(_tabData[0])
  // 如果请求的数据没有多于两个不展示tab的数据栏
  setInitData({ data: _tabData, initedError: _isLoadErr })
}

/**
 * 加载下一页数据
 */
async function loadNextPageData(page, index) {
  const tabItem = tabData.value[index]
  const { recommendScene, faulttolerant, id: filter_cate_id } = tabItem
  // 容错不请求下一页
  if (faulttolerant) return false

  const exposedGoodsId = getExposeGoodsIdForRefresh(tabItem)

  const res = await fetchBottomRecommendData({
    page,
    recommendScene,
    filter_cate_id,
    exposedGoodsId
  })

  const { products: _bffProducts = [], total, is_faulttolerant } = res?.info || {}

  // 其他tab需要去重第一个Tab前30个数据
  const handleFilterFirstTabProducts = (list = []) => {
    if (index === 0 || !cOtherTabProductsFiltersIdsMap.value) return list
    return list.filter(item => !cOtherTabProductsFiltersIdsMap.value[item.goods_id])
  }

  let formatProducts = handleFormatProductsData(handleFilterFirstTabProducts(_bffProducts))

  // 插坑商品过滤逻辑可在这里处理
  formatProducts = filterRankProductsInYmal(formatProducts, index)
  formatProducts = filterSimlarProductsInYmal(formatProducts, index)

  formatProducts = filterRepeatGoods(tabItem, formatProducts) // 去重
  updateTabItemIdsMap(tabItem, formatProducts)
  const isYmalTab = index === 0 && unref(tabData)?.[0]?.category_type === '1'
  formatProducts = setAnalysisFlag(formatProducts, isYmalTab)
  
  let _products = [...tabItem.products, ...formatProducts]

  // 清除刷新用骨架
  _products = clearSkeletonList(_products)

  if (index === 0) {
    // 只在 recommendTab 处理趋势店铺或商品
    _products = handleTrendProductSlotData(_products)
  }

  if (!tabItem.total && total) tabItem.total = total // 补全tab总数字段
  tabItem.products = _products

  // 翻页插坑
  insertPitDataWithNextPage(tabItem)
  exposeCallBackForRefresh(_products.length)

  if (is_faulttolerant === '1') {
    tabItem.faulttolerant = true
    return false
  }

  const hasNext = total > REC_LIMIT * page
  // console.log('loadNextPageData tabData', tabItem, hasNext)

  tabItem.page = page

  return hasNext
}


function onTabClick({ index }) {
  const preIndex = activeIndex.value
  setTabScrollTopWithListDom(preIndex)

  activeIndex.value = index
  const tab = tabData.value[index]

  const _isFirstLoadTab = !tab.products.length
  if (_isFirstLoadTab) {
    ListDataControllerRef.value?.[index]?.initPage?.()
  }

  daEventCenter.triggerNotice({
    daId: '1-6-5-5',
    extraData: {
      tabId: tab.id,
      tabNm: tab.name,
      index
    }
  })

  nextTick(() => {
    setTabScrollTopToLastWithIndex(index)
  })
}

/**
 * 打开快速加车
 */
function onOpenQuickAdd(payload, quickAddOptions = {}) {
  // console.log('onOpenQuickAdd', payload)
  const { target } = payload
  openQuickAddWithProductCart(payload, {
    analysisConfig: {
      code: 'youmayalsolikeDetailRecommend',
      sourceTarget: target || null,
      sa: {
        from: payload.activity_from || BaseAnalysisInfo.from,
        activity_from: payload.activity_from || BaseAnalysisInfo.from
      }
    },
    ...quickAddOptions,
  })
}

// UG-296245	商详YMAL多个行为共存时新增字段标识对应策略
const { getPageClickEvent, resetReqActions, unLoadReqActions } = useReqActions({
  isRefreshDone,
  isUseAddCartParams,
  isUseAddBoardParams,
})

async function fetchBottomRecommendData(opt = {}) {
  const res = await _fetchBottomRecommendData(opt, {
    isUseAddCartParams,
    isLoadRefreshData: isLoadRefreshData.value,
    isUseAddBoardParams: unref(isUseAddBoardParams) && unref(isFirstTab),
    pageClickEvent: getPageClickEvent(),
  }) // 补全基本参数

  if(res?.info?.products) {
    let products = res?.info?.products ?? []
    products = recommendFilterInstance.filterRecommendData(products, 'ymalRecTab')  // 推荐模块去重
    products = filterRecommendData(products) // 曝光去重
    res.info.products = products
    recommendFilterInstance.pushGoodsIds(products, 20, 'ymalRecTab')  // 推荐模块去重
  }

  return res
}

function setAnalysisFlag (products, isYmalTab) {
  return products.map(item => {
    const is_colorful_type = item.userBehaviorLabel?.appTraceInfo || item.otherMarks || ''
    return {
      ...item,
      is_noactive_refresh: isLoadRefreshData.value,
      is_addcart_refresh: isUseAddCartParams.value,
      is_addwish_refresh: !!isUseAddBoardParams.value,
      is_colorful_type: isYmalTab ? is_colorful_type : '',  // 仅限于ymal的第一个recommend-tab, 且需要productMaterial.userBehaviorLabel.appTraceInfo 有值, 或productMaterial.otherMarks有值
    }
  })
}

/**
 * 初始化时机 __MAIN__
 */
watch(
  [detailPageOnloadStatus],
  async ([loaded]) => {
    // console.log('RecommendBottom watch', loaded)
    if (!loaded) {
      unload()
      resetReqActions() // 重置请求状态
      return // 商详未初始化成功
    }
    initData()
  },
  { immediate: true }
)

onUnmounted(() => {
  // 统一卸载钩子
  unLoadReqActions()
})


/**
 * provide 注入的数据流
 */
provide('loadNextPageData', loadNextPageData)
provide('onOpenQuickAdd', onOpenQuickAdd)
/**
 * provide 注入的数据流 END
 */
</script>
<style lang="less" scoped>
.recommend-bottom {
  margin-bottom: 0.2667rem;

  &-top {
    background-color: #fff;
    &_title {
      text-align: left;
      padding: 0.2667rem 0.32rem 0;
      font-size: 14px;
      font-weight: 700;
      color: @sui_color_gray_dark1;
      &.nottab {
        padding: 0.2667rem 0.32rem;
      }
    }
  }

  &-tab {
    /* stylelint-disable-line declaration-no-important */
    position: sticky !important;
  }

  &-list {
    background: #f6f6f6;

    &.error-page {
      margin-bottom: 3rem; // 增加高度保证顶部tab滚动
    }
  }
}
</style>
