import { computed, ref } from 'vue' 
import { useMapGetters } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { insertPitDataToTab, PIT_DATA_TYPE } from '../RecommendBottom/insertPit.js'

const isInteger = (value) => {
  return /^[+]{0,1}(\d+)$/.test(value)
}

const takeUniqueStr = str => [...new Set(str.split(','))].join(',')

const EXPOSE_FEED_SESSION_KEY = 'exposeFeedIdInYmal'
export const useRankTopItem = () => {
  const { product_detail_abt_info } = useMapGetters('productDetail', ['product_detail_abt_info'])
  const ymalRank = computed(() => product_detail_abt_info.value?.ymalrank?.p?.ymalrank)
  const isShowYmalRank = computed(() => isInteger(ymalRank.value))
  const { is_paid_user } = useMapGetters('productDetail/common', ['goods_id', 'cat_id', 'is_paid_user'])
  const rankListComponentResult = ref(null)

  const fetchRankComponent = (ymal_rank_info) => {
    if (!isShowYmalRank.value) return null
    rankListComponentResult.value = ymal_rank_info || null
    return ymal_rank_info || null
    // let exposeFeedIdInYmal = sessionStorage.getItem(EXPOSE_FEED_SESSION_KEY) || ''
    // exposeFeedIdInYmal = takeUniqueStr(`${exposeFeedIdInYmal}${exposeFeedIdInYmal && curRankIdInDetail ? ',' : ''}${curRankIdInDetail}`)

    // const params = {
    //   exposed_feed_id: exposeFeedIdInYmal,
    //   goods_id: curGoodsId.value,
    //   sort: 0,
    //   scene: 'detail',
    //   cat_id: cat_id.value
    // }

    // const res = await getRankListComponentApi(params)
    // rankListComponentResult.value = +res?.code === 0 ? res?.info : null
    
    // return res
  }

  const setExposeRankToSession = () => {
    if (!isShowYmalRank.value) return 
    const currentRankId = rankListComponentResult.value?.contentCarrierId
    if (!currentRankId) return

    let exposeFeedIdInYmal = sessionStorage.getItem(EXPOSE_FEED_SESSION_KEY) || ''
    exposeFeedIdInYmal = `${exposeFeedIdInYmal ? exposeFeedIdInYmal + ',' : ''}${currentRankId}`
    exposeFeedIdInYmal = takeUniqueStr(exposeFeedIdInYmal)
    sessionStorage.setItem(EXPOSE_FEED_SESSION_KEY, exposeFeedIdInYmal)
  }

  const { product_bff_depend_data } = useMapGetters('productDetail/Recommend', ['product_bff_depend_data'])

  const rankListComponentBindProps = computed(() => ({
    flowRankingList: {
      customData: {
        from: 'detail_ymal', // 标记来源
        rankListComponentResult: rankListComponentResult.value, // 榜单接口数据
        suggestedSaleType: product_bff_depend_data.value?.suggestedSaleType, // 合规价
      }
    },
    language: {},
    sheinClubInfo: { isPaid: !!is_paid_user.value },
    index: ymalRank.value - 1, // 坑位
  }))

  const filterRankProductsInYmal = (ymalProducts = [], index) => {
    if (index !== 0) return ymalProducts
    if (!isShowYmalRank.value || !rankListComponentResult.value) return ymalProducts
    const { products = [] } = rankListComponentResult.value || {}
    const rankGoodsIds = products.map(product => product.goods_id)
    return ymalProducts.filter(item => !rankGoodsIds.includes(item.goods_id))
  }
  const insertRankTop = (tabItem) => {
    // 无法获取abt实验信息或abt分支不对
    // 推荐接口没有返回数据
    // 推荐接口返回商品数，去重后＜3个（去重逻辑后面写）
    // 榜单接口没返回主标题
    // 榜单接口没返回榜单id

    if (!isShowYmalRank.value) return
    const firstRecommendTabProducts = tabItem?.products
    if (!firstRecommendTabProducts || !rankListComponentResult.value) return
    const { products, title, contentCarrierId } = rankListComponentResult.value || {}
    if (products?.length < 3 || !title || !contentCarrierId) return

    // 过滤 ymal 跟榜单相同商品的数据
    tabItem.products = filterRankProductsInYmal(firstRecommendTabProducts)
  
    const slotData = {
      _slotType: PIT_DATA_TYPE.rankListComponent,
      _pitIndex: ymalRank.value - 1,
    }

    insertPitDataToTab(tabItem, slotData)
  }

  return {
    fetchRankComponent,
    setExposeRankToSession,
    filterRankProductsInYmal,
    // 榜单组件的 props bind
    rankListComponentBindProps,
    insertRankTop,
  }
}
