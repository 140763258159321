import { computed, watch, getCurrentInstance, onBeforeUnmount, onUnmounted } from 'vue'
import {
  useMapGetters,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'

// 页面数据全部加载完
export const useAllUpdateFinished = () => {
  const vm = getCurrentInstance()
  const store = vm.proxy.$store

  // 静态动态数据加载完
  const isAllDataReady = computed(() => store.getters['newProductDetail/common/allDataReady'])

  // // 组合购数据 todo
  // const isShowComboBuyAbt = computed(() => store.getters['productDetail/Recommend/combo_buy_abt_info']?.isShowComboBuyAbt)
  // const isShowNewComboBuy = computed(() => store.state.newProductDetail.common.isShowNewComboBuy)

  // 购物车组件数据
  const isShowCartFloor = computed(() => store.getters['newProductDetail/BagData/shouldShow'])
  const isCartFloorFetchDone = computed(() => store.state.newProductDetail.BagData.isFetchDataDone)

  // 融合后第三阶段数据
  const third_info_ready = computed(() => store.state.productDetail.common.third_info_ready)


  let unWatchers = []

  const afterAllDataFinished = ({ timeout = 10000 } = {}) => {
    return new Promise((resolve, reject) => {
  
      clearWatchers()
      let _timeOutTimer = setTimeout(() => {
        clearWatchers()
        reject('timeout')
      }, timeout)

      let currentAsyncUpdate = 0
      let totalAsyncUpadte = 0

      // Map<数据加载完成, 需要展示>
      const map = new Map()
      // map.set(isShowNewComboBuy, isShowComboBuyAbt.value)
      map.set(isCartFloorFetchDone, isShowCartFloor.value)
      map.set(third_info_ready, true)

      function _checkAsyncUpdateFinished(val) {
        if (!val) return
        currentAsyncUpdate++
        if (currentAsyncUpdate > totalAsyncUpadte) return
        if (currentAsyncUpdate === totalAsyncUpadte) {
          resolve()
          clearWatchers()
          _timeOutTimer && clearTimeout(_timeOutTimer)
        }
      }

      const _afterAllDataReady = () => {
        for (const [isLoaded, needShow] of map) {
          if (needShow && !isLoaded.value) {
            totalAsyncUpadte++
            const unWatcher = watch(() => isLoaded.value, val => _checkAsyncUpdateFinished(val))
            unWatchers.push(unWatcher)
          }
        }
      }

      if (isAllDataReady.value) {
        _afterAllDataReady()
        return
      }

      const unWatcher = watch(() => isAllDataReady.value, val => {
        if (!val) return
        _afterAllDataReady()
      })
      unWatchers.push(unWatcher)
    })
  }
  

  function clearWatchers() {
    unWatchers?.forEach?.(unWatcher => unWatcher?.())
    unWatchers = []
  }

  onBeforeUnmount(clearWatchers)

  return {
    afterAllDataFinished
  }
}


export const useColdHotAllDataReady = () => {
  const { allDataReady } = useMapGetters('newProductDetail/common', [
    'allDataReady'
  ]) // todo_upt

  let unwatchAwaitAllDataReady
  const queues = []

  const awaitColdHotAllDataReady = ({ timeout = 10000 } = {}) => {
    return new Promise((resolve, reject) => {

      if (unwatchAwaitAllDataReady) {
        queues.push({ resolve, reject })
        console.log('RecommendBottom awaitAllDataReady queues', queues)
        return
      }

      let timer = null
      const _done = () => {
        clearTimeout(timer)
        resolve(true)

        typeof unwatchAwaitAllDataReady === 'function' && unwatchAwaitAllDataReady()
        unwatchAwaitAllDataReady = null

        queues.forEach(({ resolve }) => resolve(true))
      }
      const _fail = (msg) => {
        reject(msg)
        typeof unwatchAwaitAllDataReady === 'function' && unwatchAwaitAllDataReady()
        unwatchAwaitAllDataReady = null

        queues.forEach(({ reject }) => reject(msg))
      }

      if (allDataReady.value) {
        console.log('RecommendBottom awaitAllDataReady fast', true)
        return _done()
      }

      if (timeout > 0) {
        timer = setTimeout(() => {
          console.error('RecommendBottom awaitAllDataReady timeout')
          _fail('timeout')
        }, timeout)
      }

  
      unwatchAwaitAllDataReady = watch(allDataReady, val => {
        console.log('RecommendBottom awaitAllDataReady async', val)
        if (!val) return
        _done()
      }, { immediate: true })
    })
  }
  onUnmounted(() => {
    typeof unwatchAwaitAllDataReady === 'function' && unwatchAwaitAllDataReady()
    unwatchAwaitAllDataReady = null

    queues.forEach(({ reject }) => reject())
  })
  

  return {
    awaitColdHotAllDataReady,
  }
}
