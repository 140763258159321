<template>
  <div
    class="outfit-in-ymal"
    v-expose="{
      id: '1-6-6-4',
      data: item.firstAnalysisData
    }"
    v-tap="{
      id: '1-6-6-3',
      data: {
        click_type: 'image',
        ...item.firstAnalysisData
      }
    }"
  >
    <div
      class="outfit-in-ymal-photo"
      v-if="item.insertType === 'photo'"
    >
      <img
        class="outfit-companion-img"
        :src="item.outfits_info.gtlInfo.companionImageUrl"
        @click="clickOutfitPhoto"
      />
      <div class="outfit-companion-bottom">
        <p
          class="outfit-companion-price"
          :class="{
            'normal-price':
              !Number(item.outfits_info?.gtlInfo?.bundleUnitDiscount) ||
              item.is_hit_compliance_mode
          }"
          v-html="
            salePriceWordSize({
              priceStr: item.outfits_info?.gtlInfo?.bundlePrice?.amountWithSymbol,
              mainSize: 17
            })
          "
        ></p>
        <p
          class="outfit-companion-discount"
          v-if="
            Number(item.outfits_info?.gtlInfo?.bundleUnitDiscount) &&
            !item.is_hit_compliance_mode
          "
        >
          -{{ item.outfits_info?.gtlInfo?.bundleUnitDiscount }}%
        </p>
      </div>
      <div
        class="outfit-companion-btn"
        @click.stop="clickOutfitPhoto"
        v-tap="{
          id: '1-6-6-3',
          data: {
            click_type: 'button',
            ...item.firstAnalysisData
          }
        }"
      >
        <span class="outfit-btn-txt">{{
          item.language.SHEIN_KEY_PWA_34538 || 'Get The Look'
        }}</span>
      </div>
    </div>
    <div
      class="outfit-in-ymal-items"
      v-else-if="item.insertType === 'items'"
      v-expose="{
        id: item.allGtlProducts.length === 2 ? '1-6-1-62' : '',
        data: {
          ...item.allGtlProducts?.[1],
          index: item.outfits_info.insertOrder,
          activity_from: 'newoutfit_alone',
          abtest: item.outfits_info.outfitsAbTest,
          series_no: item.allGtlProducts?.[1]?.companionItem?.detailSeriesNo
        }
      }"
    >
      <img
        class="outfit-items-bg"
        src="https://img.ltwebstatic.com/images3_ccc/2024/10/24/1b/172975251825dfbd74d02708ef0bd1cb628c205e50.png"
      />
      <p
        class="companion-items-header"
        @click="clickOutfitItems(item.allGtlProducts || [], null, item.outfits_info)"
      >
        {{ item.language.SHEIN_KEY_PWA_34538 || 'Get The Look' }}
      </p>
      <p
        class="companion-items-discount"
        v-if="
          Number(item.outfits_info?.gtlInfo?.bundleUnitDiscount) &&
          !item.is_hit_compliance_mode
        "
        @click="clickOutfitItems(item.allGtlProducts || [], null, item.outfits_info)"
      >
        <img
          class="discount-icon"
          src="https://img.ltwebstatic.com/images3_ccc/2024/10/24/1b/172975251814d89e1caaa0196fe2e5320069262d14.png"
        />
        <span>{{
          template(
            item.outfits_info?.gtlInfo?.bundleUnitDiscount + '%',
            item.language.SHEIN_KEY_PWA_34541 || 'Enjoy {0}% off'
          )
        }}</span>
      </p>
      <div
        class="companion-items-products"
        :class="{
          'just-two-outfits': item.allGtlProducts.length == 2,
          'just-three-outfits': item.allGtlProducts.length == 3,
          'over-four-outfits': item.allGtlProducts.length > 4
        }"
      >
        <div
          v-for="(gtlProduct, gtlIdx) in item.allGtlProducts || []"
          :key="gtlIdx"
          class="outfit-products-item"
          @click.stop="
            clickOutfitItems(
              item.allGtlProducts || [],
              gtlProduct,
              item.outfits_info
            )
          "
          v-show="gtlIdx <= 3"
        >
          <img
            class="products-item-img"
            :src="gtlProduct.goods_img"
          />
          <component
            :is="item.ProductCardPriceSimpleComponent"
            v-if="gtlIdx > 0"
            class="products-item-price"
            :goods-info="gtlProduct"
            :language="item.productItemsLanguage"
            :is-paid="item.isPaidUser"
            :config="item.priceCardConfig"
          />
          <p
            class="products-item-tip"
            v-else
          >
            {{ item.language.SHEIN_KEY_PWA_24207 || 'This item' }}
          </p>
          <div
            class="more-items-cover"
            v-if="item.allGtlProducts?.length > 4 && gtlIdx === 3"
          >
            <!-- <p class="more-items-txt"> {{ item.language.SHEIN_KEY_PWA_24607 || 'View More' }}</p> -->
            <p class="more-items-num">
              <span>+</span>
              <span>{{ item.uniqueGoodsNum - 3 }}</span>
            </p>
            <span class="more-items-txt">
              {{
                (item.language.SHEIN_KEY_PWA_24452 || '{0} Items').replace(
                  '{0}',
                  ''
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/sale-price-word-size.js'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import ProductCardPriceSimpleSuggested from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimpleSuggested.vue'
export default defineComponent({
  name: 'OutfitInYmal',
  directives: {
    expose,
    tap,
  },
  components: {
    ProductCardPriceSimple,
    ProductCardPriceSimpleSuggested
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['open-quick-add', 'open-gtl-popup'],
  methods: {
    salePriceWordSize,
    // 点击单项加车
    openQuickAdd(payload) {
      this.$emit('open-quick-add', payload)
    },
    clickOutfitPhoto() {
      this.$emit('open-gtl-popup') // 唤起搭配弹窗
    },
    clickOutfitItems(products, product, outfits_info) {
      if (product) {
        const {
          themeId,
          lookType,
          productInfoList,
          detailSeriesNo = '-'
        } = product.companionItem || {}
        const getBaseAnalysisData = {
          goods_num: productInfoList.length || 0,
          theme_id: themeId,
          look_type: lookType,
          series_no: detailSeriesNo,
          location: 'outfitymal'
        }
        daEventCenter.triggerNotice({
          // 点击的属性按照实际点击的那个套装报
          daId: '1-6-6-3',
          extraData: {
            click_type: 'image',
            ...getBaseAnalysisData
          }
        })
      }
      if (products.length === 2) {
        const param = {
          item: products[1],
          activity_from: 'newoutfit_alone'
        }
        this.openQuickAdd(param) // 唤起加车弹窗
        daEventCenter.triggerNotice({
          // 如果是只有2个商品的情况下，expose_module_goods_list，click_module_goods_list也要报
          daId: '1-6-1-63',
          extraData: {
            ...products[1],
            index: outfits_info.insertOrder,
            activity_from: 'newoutfit_alone',
            abtest: outfits_info.outfitsAbTest,
            series_no: products[1]?.companionItem?.detailSeriesNo
          }
        })
      } else {
        const swiperIndex = product?.swiperIndex || 0 // 点击对应商品时，锚中对应的套装，若有重复，则按照第一个套装算
        this.$emit('open-gtl-popup', swiperIndex) // 唤起搭配弹窗
      }
    }
  },
})
</script>

<style lang="less">
.outfit-in-ymal {
  width: 4.76rem;
  background: rgba(255, 255, 255, 0.79);
  margin-bottom: 0.16rem;
  border-radius: 0.106rem;
  overflow: hidden;
  > div {
    height: 100%;
    width: 4.76rem;
  }
  .outfit-in-ymal-photo {
    .outfit-companion-img {
      width: 100%;
      height: auto;
      border-radius: 0.106rem 0.106rem 0 0;
    }
    .outfit-companion-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fa6338;
      margin: 0.16rem 0 0.106rem;
      height: 0.533rem;
      .outfit-companion-price {
        font-weight: 700;
        font-size: 0.453rem;
        &.normal-price {
          color: #222;
        }
      }
      .outfit-companion-discount {
        border: 0.5px solid #fa6338;
        font-size: 0.266rem;
        border-radius: 0.053rem;
        padding: 0.026rem 0.053rem;
        margin-left: 0.053rem;
        line-height: 0.32rem
      }
    }
    .outfit-companion-btn {
      height: 0.64rem;
      line-height: 0.64rem;
      display: flex;
      justify-content: center;
      margin-bottom: 0.266rem;
      .outfit-btn-txt {
        background: #000;
        padding: 0 0.426rem;
        color: #fff;
        font-size: 0.32rem;
        font-weight: 700;
        border-radius: 2.667rem;
      }
    }
  }
  .outfit-in-ymal-items {
    color: #c44a01;
    background: linear-gradient(180deg, #f3dbcd 0.14%, #fff 19.17%), #fff;
    position: relative;
    .outfit-items-bg {
      position: absolute;
      right: 0;
      top: 0;
      width: 1.653rem;
      height: 1.093rem;
    }
    .companion-items-header {
      font-size: 0.48rem;
      line-height: normal;
      font-weight: 700;
      padding: 0.266rem 0.266rem 0;
      .line-camp(2);
    }
    .companion-items-discount {
      font-size: 0.32rem;
      line-height: normal;
      padding: 0 0.266rem 0;
      display: flex;
      align-items: center;
      margin-top: 0.053rem;
      .discount-icon {
        width: 0.373rem;
        height: 0.373rem;
        margin-right: 0.16rem;
      }
      > span {
        .line-camp(2);
      }
    }
    .companion-items-products {
      display: flex;
      flex-wrap: wrap;
      padding: 0.266rem 0.053rem 0.106rem;
      &.just-two-outfits{
        .outfit-products-item {
          &:nth-child(1) {
            border-radius: 0.053rem 0px 0px 0.053rem;
          }
          &:nth-child(2) {
            border-radius: 0px 0.053rem 0.053rem 0px;
          }
        }
      }
      &.just-three-outfits {
        .outfit-products-item {
          &:nth-child(n) {
            border-radius: 0;
          }
          &:first-child {
            width: 4.546rem;
            height: 4.546rem;
          }
        }
      }
      &.over-four-outfits {
        .outfit-products-item {
          &:nth-child(4) {
            .products-item-price,
            .products-item-tip {
              display: none;
            }
          }
        }
      }
      .outfit-products-item {
        background: #f6f6f6;
        width: 2.213rem;
        height: 2.96rem;
        margin: 0 0.053rem 0.106rem;
        overflow: hidden;
        position: relative;
        &:nth-child(1) {
          border-radius: 0.053rem 0px 0px 0px;
        }
        &:nth-child(2) {
          border-radius: 0px 0.053rem 0px 0px;
        }
        &:nth-child(3) {
          border-radius: 0px 0px 0px 0.053rem;
        }
        &:nth-child(4) {
          border-radius: 0px 0px 0.053rem 0px;
        }
      }
      .products-item-img {
        width: 100%;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
      .products-item-price {
        z-index: 1;
        position: absolute;
        left: 0.106rem;
        bottom: 0.106rem;
        background: rgba(246, 246, 246, 0.88);
        border-radius: 0.053rem;
        color: #fa6338;
        font-weight: 700;
        padding: 0.053rem 0.133rem;
        .price-content {
          margin-right: 0;
        }
        .product-card__camel-case-price {
          font-size: 0.2667rem;
        }
        .product-card__camel-case-price__number-before-dot {
          font-size: 0.373rem;
        }
        .prices-info__suggested-sale-price {
          display: none;
        }
        .product-card__text-label {
          display: none;
        }
      }
      .products-item-tip {
        z-index: 1;
        position: absolute;
        color: #fff;
        height: 0.426rem;
        width: 100%;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        text-align: center;
        line-height: 0.426rem;
        font-size: 0.266rem;
      }
      .more-items-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        color: #fff;
        padding: 0 0.106rem;
        .more-items-num {
          font-size: 0.373rem;
          line-height: 0.453rem;
        }
        .more-items-txt {
          font-size: 0.32rem;
          line-height: 0.373rem;
          .line-camp(2);
          word-break: break-all;
        }
      }
    }
  }
}
</style>
