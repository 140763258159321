import { unref } from 'vue'

// 插坑出现的优先级为outfit > 榜单 > 新客banner > 低频购banner > 问卷 > 刷新按钮
export const PIT_DATA_TYPE = Object.freeze({
  /**
   * 问卷插坑
  */
  surveyQuestion: 'surveyQuestion',
  /**
   * 新搭配插坑
   */
  outfitYmal: 'outfitYmal',
  /**
   * 榜单插坑
   */
  rankListComponent: 'rankListComponent',
  /**
   * 点推插坑
   */
  clickRec: 'clickRec',
  /**
   * 刷新插坑
   */
  refreshCard: 'refreshCard',
  /**
   * 骨架插坑
   */
  skeletonCard: 'skeletonCard',
})

/**
 * 插坑优先级，如果下标相同，优先级高的在前
 */
export const PIT_DATA_TYPE_SORT = [
  PIT_DATA_TYPE.outfitYmal, 
  PIT_DATA_TYPE.rankListComponent, 
  PIT_DATA_TYPE.surveyQuestion,
  PIT_DATA_TYPE.refreshCard
]

/**
 * 底部推荐位插坑方法
 */

// 插坑相关函数
const insertPitDataToList = (tabItem, pitData) => {
  const { products: list, dynamicItems } = tabItem
  if (list.length - 1 < pitData._pitIndex) return false // 当前坑位不满足坑位数据

  if (pitData?.isDynamic) { // 动态坑位
    dynamicItems.push({
      targetSlotIndex: pitData._pitIndex,
      insertItems: [pitData]
    })

    return true
  }
  list.splice(pitData._pitIndex, 0, pitData)

  console.warn('insert-pit-success', `index: ${pitData._pitIndex}`, pitData)
  return true
}


export const insertPitDataToTab = (tabItem, pitData) => {
  if (typeof pitData?._pitIndex === 'undefined') throw '_pitIndex is required'

  const _tabItem = unref(tabItem)
  if (!_tabItem?.pitPositionList) return

  pitData._sourceIndex = pitData._pitIndex
  const { pitPositionList = [], pitPositionListDone = [] } = _tabItem

  // 需要排序
  if (PIT_DATA_TYPE_SORT.includes(pitData._slotType)) {
    const _handleSort = (_positionList) => {
      if (!_positionList?.length) return
      const _sameIndexPitItem = _positionList.find(d => d._pitIndex === pitData._pitIndex && PIT_DATA_TYPE_SORT.includes(d._slotType))
      if (!_sameIndexPitItem) return
      console.log('insertPitDataToTab-same-index', _sameIndexPitItem)
      const _curSortLevel = PIT_DATA_TYPE_SORT.indexOf(pitData._slotType)
      const _sameSortLevel = PIT_DATA_TYPE_SORT.indexOf(_sameIndexPitItem._slotType)

      if (_curSortLevel < _sameSortLevel) return // 当前插坑优先级高，不需要处理

      pitData._pitIndex++ // 优先级低的插坑下标+1
      _handleSort(_positionList) // 继续排序防止下标继续重复
    }

    _handleSort([...pitPositionList, ...pitPositionListDone]) // 插坑排序
  }

  const hasInsert = insertPitDataToList(_tabItem, pitData)
  if (hasInsert) {
    pitPositionListDone.push(pitData)
    return
  }

  pitPositionList.push(pitData) // 插坑失败，加入坑位队列
}

// 翻页插坑
export const insertPitDataWithNextPage = (tabItem) => {
  if (!tabItem) return
  const { pitPositionList, pitPositionListDone } = tabItem
  if (pitPositionList?.length) {
    for (let i = pitPositionList.length - 1; i >= 0; i--) {
      if (insertPitDataToList(tabItem, pitPositionList[i])) {
        pitPositionList.splice(i, 1) // 插坑成功删除元素
        pitPositionListDone.push(pitPositionList[i])
      }
    }
  }
}

// 获取所有插坑过的坑位
export const getPitPositionListByTabItem = (tabItem) => {
  const { pitPositionList = [], pitPositionListDone = [] } = tabItem || {}
  return [...pitPositionList, ...pitPositionListDone]
}
