

import {
  unref,
  getCurrentInstance,
  computed,
  nextTick,
  watch,
} from 'vue'
import {
    useMapGetters,
    useMapMutations,
    useMapStateV2,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { getRecommendCollectSimilar } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/apis.js'

import { PIT_DATA_TYPE } from '../insertPit.js'
import { sleep } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { QuickAddCartState } from 'public/src/pages/components/product/quick_add_v3/plugins/syncGlobalData/index.js'
import { useAllUpdateFinished, useColdHotAllDataReady } from 'public/src/pages/goods_detail_v2/hooks/use-data-cycle.js'
import { ymalExposureListInstance } from '../utils.js'
import { getCookie } from '@shein/common-function'

// eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
const _isDebugger = typeof window !== 'undefined' ? getCookie('refreshListDebug') !== 'undefined' : false

const getRecommendBottomItemScrollTop = (goodsId) => {
  const target = document.querySelector(`.recommend-bottom-list .recommend__goods-item[data-id="${goodsId}"]`)
  if (!target) return
  return target?.getBoundingClientRect()?.top // 相对于视口的矩形信息
}

export const useRouterInfo = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')

  const isDetailBack = () => {
    return vm.proxy.$historyCon?.direction === -1
  }
  return {
    isDetailBack
  }
}

const useRootLoading = () => {
  const { changeRootSLoadingStatus } = useMapMutations('', ['changeRootSLoadingStatus'])

  const setRootLoading = () => {
    changeRootSLoadingStatus({
      show: true,
      curPageScrollFix: true
    }) // 开启loading
  }
  const closeRootLoading = () => {
    changeRootSLoadingStatus({
      show: false,
      curPageScrollFix: false
    }) // 关闭loading
  }

  return {
    setRootLoading,
    closeRootLoading,
  }
}

const ymalTabDataCacheMap = new Map()
const MAX_CACHE_LIMIT = 10 // 缓存最大层级
const MAX_CACHE_SECOND = 60 * 20 // 缓存最大时间

export const useYmalClickRec = ({
  goodsTransformationWithSetup,
  mainGoodsId, // 商详主商品id
  tabData,

  isUseAddCartParams,
  isUseAddBoardParams,
  isFirstTab
}) => {

  // window.__ymalTabDataCacheMap = ymalTabDataCacheMap // todo del

  const {
    isDetailBack,
  } = useRouterInfo()

  const {
    setRootLoading,
    closeRootLoading,
  } = useRootLoading()

  const { afterAllDataFinished } = useAllUpdateFinished()
  const { awaitColdHotAllDataReady } = useColdHotAllDataReady()

  const { ymal_click_recommend_config } = useMapGetters('productDetail/Recommend', [
    'ymal_click_recommend_config',
  ])
  const { main_sale_attribute_list } = useMapGetters('productDetail/common', ['main_sale_attribute_list'])

  const isUseClickRec = computed(() => !!ymal_click_recommend_config.value?.show)


  const getYmalTabCache = ({ goods_id }) => {
    return ymalTabDataCacheMap.get(goods_id)
  }

  const setYmalTabCache = ({ goods_id, data }) => {
    if (!data) {
      ymalTabDataCacheMap.delete(goods_id)
      return
    }

    if (ymalTabDataCacheMap.has(goods_id)) {
      ymalTabDataCacheMap.delete(goods_id) // 先删除保证插入顺序
    }

    ymalTabDataCacheMap.set(goods_id, data)
  }

  // 删除无用的缓存，防止内存占用过多
  const clearUnuseCache = ({ mainGoodsId }) => {

    // 返回后删除前进的缓存
    const keys = Array.from(ymalTabDataCacheMap.keys())
    const currentIndex = keys.findIndex(goods_id => goods_id === mainGoodsId)

    if (currentIndex === -1) return // 当前商详页无缓存
    keys.splice(currentIndex + 1).forEach(goods_id => { // 删掉当前商详页之后的缓存
      ymalTabDataCacheMap.delete(goods_id)
    })

    // 删除过期缓存
    const now = Date.now()
    const keys2 = Array.from(ymalTabDataCacheMap.keys())
    keys2.forEach(goods_id => {
      const _data = ymalTabDataCacheMap.get(goods_id)
      if (((now - _data.ts) / 1000) > MAX_CACHE_SECOND) {
        ymalTabDataCacheMap.delete(goods_id)
      }
    })

    // 删除超出最大缓存限制的缓存
    const keys3 = Array.from(ymalTabDataCacheMap.keys())
    if (keys3.length > MAX_CACHE_LIMIT) {
      keys3.splice(0, keys3.length - MAX_CACHE_LIMIT).forEach(goods_id => {
        ymalTabDataCacheMap.delete(goods_id)
      })
    }
  }


  const _lastClickRecInfo = {
    click_goods_id: '',
    main_goods_id: '',
  }

  const loadTabItemClickRec = async ({ goods_id, cat_id, tabItem, itemIndex }) => {

    _lastClickRecInfo.click_goods_id = goods_id
    _lastClickRecInfo.main_goods_id = unref(mainGoodsId)


    if (!isUseClickRec.value) return

    const { clickRecCount } = ymal_click_recommend_config.value
    if (!clickRecCount) return

    const exposeGoodsId = ymalExposureListInstance.getList(120) || []

    // 过滤当前主商品spu下的所有商品
    const goodsIds = (unref(main_sale_attribute_list) || []).map(d => d.goods_id) || []
    exposeGoodsId.push(...goodsIds)

    const res = await getRecommendCollectSimilar({
      goodsId: goods_id,
      cateId: cat_id,
      isAddCart: unref(isUseAddCartParams) ? '1' : '0',
      addToBoard: unref(isUseAddBoardParams) && isFirstTab.value ? '1' : '0',
      pageQueryParam: {
        queryType: 5
      },
      ymalClickRecReqParam: {
        exposeGoodsId: exposeGoodsId.join(','),
      }
    })

    const _recProducts = (res?.info?.ymalClickRecProducts || []).slice(0, clickRecCount)
    if (_recProducts?.length !== clickRecCount) return

    if (!tabItem?.dynamicItems) return // tab异常不执行
    // 极端场景：如果点击商品1，推荐数据还没展示，又点击了商品2，商品1的点后推不用再展示
    if (_lastClickRecInfo.click_goods_id !== goods_id) return
    if (_lastClickRecInfo.main_goods_id !== unref(mainGoodsId)) return


    if (tabItem.dynamicItems.some(item => item.triggerGoodsId === goods_id)) {
      console.error('RecommendBottom clickRec has inserted', goods_id)
      return // 已经插入过的商品不再插入
    }

    tabItem.dynamicItems.push({
      itemIndex, // 调试用
      triggerGoodsId: goods_id,
      // *    triggerGoodsId: '' // 商品id
      // *    targetSlotIndex: 0 // 目标插入的位置
      // *    insertItems: [] // 插入的商品
      insertItems: _recProducts.map((item, slotIndex) => {
        return {
          ...goodsTransformationWithSetup(item),
          _clickTriggerRecTitlelabelLang: item?.clickTriggerRecTitle?.labelLang, // 中间层返回点推多语言
          divideTime: 'recommend', // 商卡使用，cookie 加 refreshListDebug 值为 1 可以提供调试
          _slotType: PIT_DATA_TYPE.clickRec, // 点推插槽
          _from_goods_id: goods_id,
          _click_goods_position_index: itemIndex,
          _position_index: slotIndex,
        }
      })
    })

    if (_isDebugger) console.warn('RecommendBottom clickRec loadTabItemClickRec done', tabItem.dynamicItems)

    return true // 点推成功
  }


  const {
    addSkcCompleteStatus,
  } = useMapStateV2('newProductDetail/common', [
    'addSkcCompleteStatus'
  ]) // todo_del

  // 加车状态
  watch(() => addSkcCompleteStatus.value, (isAddSuccess) => {
    if (!isAddSuccess) return

    const goodIds = [...ymalTabDataCacheMap.keys()]
    if (!goodIds.length) return

    const lastCache = ymalTabDataCacheMap.get(goodIds[goodIds.length - 1])
    // if (lastCache.clickGoodsId != unref(mainGoodsId)) return
    lastCache.clickGoodsAdded = true

  })


  // 点推底部推荐位数据初始化
  const initClickRecData = async ({
    checkGoodsId,
    setInitData,
  }) => {

    const _awaitUseClickRecInitData = async ({ goods_id }) => {
      if (!isDetailBack()) return // 非返回的情况
      await awaitColdHotAllDataReady({ timeout: 10000 }) // 等候静态实时链路加载完，需要保证abt配置是最新的
      if (!isUseClickRec.value) return

      const tabCacheData = getYmalTabCache({ goods_id: unref(goods_id) }) // 获取缓存数据
      const { ymalTabData } = tabCacheData || {}
      if (!ymalTabData?.length) return // 不存在缓存数据
      return tabCacheData
    }

    const _clickRecData = await _awaitUseClickRecInitData({ goods_id: checkGoodsId })
    // console.log('RecommendBottom clickRec init', checkGoodsId, _clickRecData)
    // 如果当前页面是返回，且满足点推条件，直接取缓存数据
    if (!_clickRecData) {
      return // 无需使用点推缓存数据
    }

    let execNext = true
    const _isCanExecNext = () => {
      if (mainGoodsId.value !== checkGoodsId) {

        // console.log('RecommendBottom clickRec init changeId ', checkGoodsId, mainGoodsId.value)
        return false // 执行异步操作后要确保是同一个商品
      }
      return execNext
    }

    const taskCease = () => {
      execNext = false
      closeRootLoading()
    }

    const _taskExecute = async () => {

      _isDebugger && console.warn('RecommendBottom clickRec initStart', checkGoodsId)

      await nextTick()
      const { ymalTabData: _tabData, clickGoodsId, clickCatId, clickTargetTop, needClickRec, clickTargetItemTop, itemIndex, clickGoodsAdded } = _clickRecData || {}

      ymalTabDataCacheMap.delete(checkGoodsId) // 清除掉当前缓存

      // 先清空ymal tab点推商品
      let _ymalDynamicItems = []
      if (_tabData[0].dynamicItems.length) {
        _ymalDynamicItems = _tabData[0].dynamicItems?.filter(d => !d._isRemove) || [] // 删除软删除商品
        _tabData[0].dynamicItems = []
      }

      setInitData({ data: _tabData }) // 先更新底部dom数据

      const _ymalTabData = tabData.value[0]
      if (_ymalDynamicItems?.length) {
        // 延迟上动态插槽数据，防止插坑抖动
        sleep(20).then(() => {
          if (!_isCanExecNext()) return
          // 延迟上动态插槽数据，防止插坑抖动
          _ymalTabData.dynamicItems = _ymalDynamicItems
        })
      }


      try {
        await afterAllDataFinished({ timeout: 3000 }) // 确保影响商详高度的数据加载完成
      } catch(e) {
        /* 超时仍然继续 */ 
        _isDebugger && console.warn('RecommendBottom clickRec afterAllDataFinished timeout')
      }

      _isDebugger && console.warn('RecommendBottom clickRec afterAllDataFinished', checkGoodsId)

      if (!_isCanExecNext()) return

      await Promise.all([nextTick, sleep(200)])  // 等待商品项目dom更新

      _isDebugger && console.warn('RecommendBottom ClickRec initData')

      if (!_isCanExecNext()) return

      await Promise.all([nextTick, sleep(200)])  // 等待商品项目dom更新

      _isDebugger && console.warn('RecommendBottom ClickRec ymalDynamicItems',  tabData.value[0].dynamicItems)


      if (!_isCanExecNext()) return

      _isDebugger && console.warn('RecommendBottom ClickRec clickTargetTop before', {
        clickTargetTop,
        scrollTop: document.documentElement.scrollTop,
        scrollHeight: document.documentElement.scrollHeight,
        tabData: tabData.value,
      })

      const nowItemScrollTop = getRecommendBottomItemScrollTop(clickGoodsId)

      window.scrollTo({
        top: nowItemScrollTop ? nowItemScrollTop - clickTargetItemTop : clickTargetTop, // 滚动到目标的垂直位置
        behavior: 'smooth'        // 平滑滚动
      })

      if (!needClickRec) return // 不需要点推

      await sleep(200)

      _isDebugger && console.warn('RecommendBottom ClickRec clickTargetTop', {
        clickTargetTop,
        scrollTop: document.documentElement.scrollTop,
        scrollHeight: document.documentElement.scrollHeight,
        tabData: tabData.value,
      })

      if (!_isCanExecNext()) return

      const { clickAndAdd, clickAndAddBag, clickAndNoAddBag } = unref(ymal_click_recommend_config) || {}

      const _hitAbt = clickAndAdd
        || clickAndAddBag && clickGoodsAdded // 点击加车后推
        || clickAndNoAddBag && !clickGoodsAdded // 点击非加车后推

      if (!_hitAbt) return

      // eslint-disable-next-line require-atomic-updates
      _clickRecData.needClickRec = false // 防止重复点推


      loadTabItemClickRec({ goods_id: clickGoodsId, cat_id: clickCatId, tabItem: _ymalTabData, itemIndex }).then((done) => {
        if (!done || !_isCanExecNext()) return

        const _clickItem = _tabData[0].products[itemIndex]
        _clickItem._hasClickRec = true
      })

      clearUnuseCache({ mainGoodsId: checkGoodsId }) // 清除其他商品的缓存，防止返回后点击数量过多
    }

    const taskExecute = async () => {
      setRootLoading()

      let res
      try {
        res = await _taskExecute()
      } catch (e) { /*  */ }

      setRootLoading()
      await nextTick() // 修复vue3 loading蒙层不消失问题
      closeRootLoading()
      return res
    }


    return {
      taskExecute,
      taskCease,
    }
  }

  // 点击商品后的点推
  const handleClickRecAfterClickItem = (item) => {

    if (!unref(isUseClickRec)) return

    // 点推处理，存入缓存
    const _mainGoodsId = unref(mainGoodsId)
    const { goods_id, index: itemIndex }  = item
    const clickGoodsId = String(goods_id)
    const clickTargetItemTop = getRecommendBottomItemScrollTop(clickGoodsId) // 相对于视口的矩形信息
    const scrollTop = document.documentElement.scrollTop // 获取垂直滚动偏移量

    const _tabData = unref(tabData)?.map?.((tab, index) => {
      const _isYmal = index === 0
      if (_isYmal) {

        tab?.dynamicItems?.forEach?.(item => {
          item.insertItems.forEach(d => d._click_rec_mounted = true)
        }) // 增加已经插入过的商品标识

        return {
          ...tab,
          products: tab.products.concat(),
        }
      }

      return {
        ...tab,
        products: [],
      }
    })

    clearUnuseCache({ mainGoodsId: _mainGoodsId }) // 清除其他商品的缓存，防止返回后点击数量过多

    const _clickItem = _tabData[0].products[itemIndex]
    const needClickRec = !_clickItem._slotType // 点推的商品、插坑
      && !_clickItem._hasClickRec // 已经点推过的商品不需要再次点推

    const clickCatId = _clickItem.cat_id


    if (_isDebugger) {
      console.log('RecommendBottom clickRec clickItem', {
        clickGoodsId,
        itemIndex,
        clickCatId,
        mainGoodsId: _mainGoodsId,
        clickTargetTop: scrollTop,
        clickTargetItemTop,
      })
    }


    setYmalTabCache({ goods_id: _mainGoodsId, data: {
      itemIndex,
      needClickRec,
      clickGoodsId,
      clickGoodsAdded: null,
      clickCatId,
      ymalTabData: _tabData,
      clickTargetTop: scrollTop,
      clickTargetItemTop,
      ts: Date.now(),
    } })
  }

  // 点击商品快车后的点推
  const handleClickRecAfterOpenQuickAdd = async (payload, {
    onOpenQuickAdd,
    tabItem,
  }) => {

    if (!unref(isUseClickRec)) return onOpenQuickAdd(payload)

    const { index } = payload
  
    const _sourceItem = tabItem?.products?.[index]
    const _canItemClickRec = () => {
      if (!_sourceItem) return false
      return !_sourceItem._slotType && !_sourceItem._hasClickRec
    }

    const { clickAndAdd, clickAndAddBag, clickAndNoAddBag } = unref(ymal_click_recommend_config) || {}
  
    return onOpenQuickAdd(payload, {
      clickCallBack: {
        onDrawerStatusChange: (isOpen) => {
          // 点推推荐商品
          if (isOpen) return
          if (!_canItemClickRec()) return

          if (clickAndAddBag && QuickAddCartState.addCartStatus // 加车触发点推
            || clickAndNoAddBag && !QuickAddCartState.addCartStatus // 非加车触发点推
            || clickAndAdd // 用户点击整个商卡（商品或快速加车icon），都触发点后推
          ) {
            _sourceItem._hasClickRec = true
            const { goods_id, cat_id } = payload.item
            loadTabItemClickRec({ goods_id, cat_id, tabItem, itemIndex: index })
          }
        },
      }
    })
  }


  return {

    isUseClickRec,
    ymal_click_recommend_config,

    handleClickRecAfterClickItem,
    handleClickRecAfterOpenQuickAdd,
    initClickRecData,
  }
}


/**
 * 把点推插坑数据按下标删除，防止商品删除后点推数据仍然存在
 */
export const clearClickRecByIndex = (tabItem, afterIndex = 0) => {
  if (!tabItem?.dynamicItems?.length) return false
  // 清除掉点推掉插槽数据
  const dynamicIndex = tabItem.dynamicItems.findIndex(d => d.itemIndex >= afterIndex)
  if (dynamicIndex === -1) return false
  // tabItem.dynamicItems.splice(dynamicIndex)
  for (let i = dynamicIndex; i <  tabItem.dynamicItems.length; i++) {
    tabItem.dynamicItems[i]._isRemove = true // 软删除
  }

  return true
}
