import { watch } from 'vue'

export const useReqActions = ({
  isRefreshDone,
  isUseAddCartParams,
  isUseAddBoardParams,
}) => {

  let actionsList = []
  // addCart-wish-refresh-refresh-addCart...
  const ACTION_TYPE_NAME_MAP = {
    0: 'addCart',
    1: 'wish',
    2: 'refresh',
  }

  // 按照实际“有传对应行为参数去请求过ymal新策略”，才要记录
  let _unWatch = null

  const getPageClickEvent = () => {
    // console.log('actionsList', actionsList)
    if (!actionsList.length) return ''
    return actionsList.join('-')
  }

  const unLoadReqActions = () => {
    _unWatch && _unWatch()
  }

  const _loadWatch = () => {
    unLoadReqActions()
    _unWatch = watch(() => [
      isUseAddCartParams.value, // 加购刷
      isUseAddBoardParams.value, // 收藏刷新
      isRefreshDone.value, // 手动/自动刷新
    ], (newList, oldList) => {
      newList.forEach((item, index) => {
        if (typeof item === 'number' && item <= 0) return
        if (!item) return

        if (item === oldList[index]) return
        const actionName = ACTION_TYPE_NAME_MAP[index]
        // if (actionsList.length && actionsList[actionsList.length - 1] === actionName) return // 若用户有单个行为的多次重复，则以”每个行为的最新一次数据“为最终排序判断依据
        actionsList.push(actionName)
      })
    })
  }

  _loadWatch()


  // 切换skc时要重置事件
  const resetReqActions = () => {
    unLoadReqActions()
    actionsList = []
    setTimeout(() => {
      _loadWatch()
    }, 200)
  }

  return {
    getPageClickEvent,
    resetReqActions,
    unLoadReqActions,
  }
}
