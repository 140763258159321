<template>
  <div>
    <div
      v-if="item?.isFashionStore"
      v-expose="{
        id: '1-6-5-6',
        data: getAnalysisData(true)
      }"
    >
      <FashionStore
        class="detail-ymal-fashionstore-card"
        :language="language"
        :item="item"
        :config="productItemConfig"
        :index="index"
        :constant-data="constantData"
        :analysis-data="storeAnalysisData"
        :clickItemCallback="storeClickItemCallback"
        @exposedCard="$emit('exposedCard', $event)"
      />
    </div>
    
    <div
      v-else-if="item?.isTrendContent"
      v-expose="{
        id: '1-6-5-6',
        data: getAnalysisData()
      }"
    > 
      <TrendListContent
        class="detail-ymal-trendlist-card"
        :language="language"
        :item="item"
        :config="productItemConfig"
        :index="index"
        :constant-data="constantData"
        :analysis-data="trendAnalysisData"
        :clickItemCallback="trendClickItemCallback"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TrendProductItem'
})
</script>
<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import FashionStore from 'public/src/pages/components/product/carrier_ui/FashionStore/index.vue'
import TrendListContent from 'public/src/pages/components/product/carrier_ui/trend-list/index.vue'
import { useRouter } from 'public/src/pages/common/RiskCommon/utils.js'
import { computed } from 'vue'
import { stringifyQueryString } from '@shein/common-function'

const STORE_SRC_MODULE = 'ymal_store'
const TREND_SRC_MODULE = 'ymal_trend'

const router = useRouter()

const props = defineProps({
  index: {
    type: Number,
    default: 0
  },

  item: {
    type: Object,
    default: () => ({})
  },

  productItemConfig: {
    type: Object,
    default: () => ({})
  },

  language: {
    type: Object,
    default: () => ({})
  },

  constantData: {
    type: Object,
    default: () => ({})
  },

  mainGoodsId: {
    type: [Number, String],
    default: ''
  }
})

function getSrcIdenifier(isStore = false) {
  const { goods_id, store_code, deliveryInfo } = props.item || {}
  const jc = isStore ? store_code : `趋势选品_${deliveryInfo?.content_carrier_id || ''}`
  return `main_goods_id=${props.mainGoodsId}\`goods_id=${goods_id}\`ps=${props.index + 1}\`jc=${jc}`
}

function getClickUrl(isStore = false) {
  const { store_code, goods_id, cat_id, deliveryInfo } = props.item || {}
  const { langPath } = props.constantData
  const src_tab_page_id = getSaPageInfo.tab_page_id
  const src_identifier = getSrcIdenifier(isStore)

  if (isStore) {
    const query = {
      store_code,
      adp: goods_id,
      main_goods_id: props.mainGoodsId,
      main_cate_id: cat_id,
      src_module: STORE_SRC_MODULE,
      src_identifier: src_identifier,
      src_tab_page_id,
      entry_from: 'page_goods_detail`-`-`trend_rcmd',
      page_from: 'page_goods_detail',
    }
    return `${langPath}/store/home?${stringifyQueryString({
      queryObj: query
    })}`
  }

  const {
    trend_word_id = '',
    product_select_id = '',
    product_select_url_id = ''
  } = deliveryInfo

  const query = {
    trendId: trend_word_id,
    selectId: product_select_id,
    selectUrlId: product_select_url_id,
    src_module: TREND_SRC_MODULE,
    adp: goods_id,
    catId: cat_id,
    entry_from: 'page_goods_detail`-`-`trend_rcmd',
    page_from: 'page_goods_detail',
    src_tab_page_id,
    src_identifier,
  }

  return `${langPath}/trend-landing?${stringifyQueryString({
    queryObj: query
  })}`
}

function storeClickItemCallback() {
  const url = getClickUrl(true)
  router.push(url)
}

function trendClickItemCallback() {
  const url = getClickUrl()
  router.push(url)
}

function getAnalysisData(isStore) {
  const deliveryInfo = props.item.deliveryInfo || {}
  return {
    src_module: isStore ? STORE_SRC_MODULE : TREND_SRC_MODULE,
    src_identifier: getSrcIdenifier(isStore),
    ...(isStore ? {} : {
      trend_word_id: deliveryInfo?.trend_word_id || '',
    })
  }
}

const storeAnalysisData = computed(() => ({
  clickId: '1-6-5-7',
  data: getAnalysisData(true)
}))

const trendAnalysisData = computed(() => ({
  clickId: '1-6-5-7',
  data: getAnalysisData()
}))

</script>

<style lang="less">
.detail-ymal-fashionstore-card, .detail-ymal-trendlist-card {
  margin-bottom: .16rem;
}
</style>
