import { getCurrentInstance, onMounted, onUnmounted, ref, unref, watch, nextTick, computed } from 'vue'
import { useMapGetters, useMapStateV2, useMapActions, useMapMutations } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { useRegisterDomExpose, getAnalysisBoxBindDataTemplate } from 'public/src/pages/goods_detail_v2/utils/recommend.js'
import recommendExposureListInstance from 'public/src/pages/goods_detail_v2/utils/recommendExposureList.js'
import { recommendExposureList } from 'public/src/pages/goods_detail_v2/utils/recommendExposureList.js'
import { throttle } from '@shein/common-function'

// 埋点数据
export const BaseAnalysisInfo = {
  from: 'you_may_also_like',
  module: 'module',
  code: 'youmayalsolikeDetailRecommend',
  posKey: 'ProductDetailRecommend,ymalrecommend'
}

let traceId = ''
export function getBoxBindData(tab) {
  if (!tab) return {}
  const { analysisInfo } = tab
  if (!traceId) {
    traceId = (typeof gbExposeTraceid == 'function' && gbExposeTraceid('getComponent', { componentName: 'recommend' })) || ''
  }
  return getAnalysisBoxBindDataTemplate({
    ...BaseAnalysisInfo,
    traceId,
    'is-fault-tolerant': tab.faulttolerant ? '1' : '0',
    ...analysisInfo,
  })
}


export const ERecommendScene = Object.freeze({
  /**
   * 整合所有类目、混品
   */
  'allCate': 'allCate',
  /**
   * youMayLike
   */
  'youMayLike': 'youMayLike',
  /**
   * obw
   */
  'oftenBought': 'oftenBought'
})

const categoryTypeSceneMap = Object.freeze({
  /**
   * 整合所有类目（合并场景，类目237/175，推荐242/131）
   */
  '0': ERecommendScene.allCate,
  /**
   * 混品 推荐131
   */
  '1': ERecommendScene.allCate,
  /**
   * youMayLike 推荐4
   */
  '2': ERecommendScene.youMayLike,
  /**
   * obw 推荐53
   */
  '3': ERecommendScene.oftenBought
})

export const getFormatTabData = (categories = []) => {


  // 是否有混品tab
  return categories.map((d, index) => {
    const {
      cat_id,
      cat_name,
      category_type,
    } = d

    // 埋点数据
    const _tabIndex = index
    const analysisInfo = {
      ['tab-list']: `${_tabIndex}\`${cat_id || '-'}\`-\`${cat_name}`
    }

    return {
      isYmalTab: index === 0,
      products: [],
      recommendScene: categoryTypeSceneMap[category_type] || '',
      category_type,

      id: cat_id, // 第一个位空
      name: cat_name,
      total: 0,
      page: 0, // 当前页
      /**
       * 是否容错
       */
      faulttolerant: false,
      analysisInfo,
      /**
       * 当前tab插坑队列
       */
      pitPositionList: [],
      /**
       * 当前tab插坑完成数据
       */
      pitPositionListDone: [],
      /**
       * 点推动态插坑数据
       */
      dynamicItems: [],
      /** */
      itemIdsMap: {},
      /**
       * 删除状态
       */
      deleteItemsFlag: false,
    }
  })
}

// yaml 是否已加载和已曝光过 (滚动时可能 ymal 还没加载， 添加这个状态用于判断)
export const useYmalLoadedAndExpose = () => {
  const {
    update_ymal_exposed,
    update_ymal_loaded
  } = useMapMutations('productDetail/Recommend', [
    'update_ymal_exposed',
    'update_ymal_loaded'
  ]) 

  const {
    is_ymal_exposed
  } = useMapStateV2('productDetail/Recommend', [
    'is_ymal_exposed'
  ]) 

  const vm = getCurrentInstance()

  const { goods_id } = useMapGetters('productDetail/common', [
    'goods_id',
  ])

  const _regExposeWatcher = () => {
    useRegisterDomExpose({
      $el: vm?.proxy?.$el,
      callback: () => {
        update_ymal_exposed(true)
      }
    })
  }
  onMounted(() => {
    _regExposeWatcher()
    update_ymal_loaded(true)
  })

  onUnmounted(() => {
    update_ymal_exposed(false)
    update_ymal_loaded(false)
  })

  // goods_id变化时，重置状态
  watch(() => goods_id.value, () => {
    // 曝光过会销毁曝光实例，要重新注册曝光监听
    if (is_ymal_exposed.value) {
      _regExposeWatcher()
    }
    update_ymal_exposed(false)
  })
}

/**
 * 加车后未曝光状态刷新底部推荐位实验
 */
export const useAfterAddCartRefresh = ({ initData, insterOutfitYmal }) => {
  const isUseAddCartParams = ref(false)
  // 刷新中
  const isYmalAddBagRefreshing = ref(false)

  const {
    fs_abt
  } = useMapGetters('productDetail/common', [
    'fs_abt',
  ])
  /**
   * 是否加车后增加参数
   */
  const is_bottomrec_use_addcart_params = Number(unref(fs_abt).youmayalsolikeaddbag?.p) === 1
  if (!is_bottomrec_use_addcart_params) {
    return {
      isUseAddCartParams,
      isYmalAddBagRefreshing,
    }
  }

  const {
    is_ymal_exposed
  } = useMapStateV2('productDetail/Recommend', [
    'is_ymal_exposed'
  ])  

  const _isValidRefresh = () => !is_ymal_exposed.value && addSkcCompleteStatus.value
  
  const {
    addSkcCompleteStatus,
  } = useMapStateV2('newProductDetail/common', [
    'addSkcCompleteStatus'
  ]) // todo_del
  const { goods_id } = useMapGetters('productDetail/common', [
    'goods_id',
  ])

  // goods_id变化时，重置状态
  watch(() => goods_id.value, () => {
    isUseAddCartParams.value = false
    isYmalAddBagRefreshing.value = false
  })

  watch(() => addSkcCompleteStatus.value, () => {
    if (!_isValidRefresh()) return
    isUseAddCartParams.value = true
  })

  // 组件渲染时，判断是否满足增加参数参数
  if (_isValidRefresh()) {
    isUseAddCartParams.value = true
  }

  let _refreshTimer = null
  const onRefreshData = () => {
    clearTimeout(_refreshTimer)
    // 主商品加车成功后，底部推荐位未曝光，需要刷新
    _refreshTimer = setTimeout(() => {
      if (!isUseAddCartParams.value) return
      // 防止重复刷新
      if (isYmalAddBagRefreshing.value) return

      initData().then(() => {
        // 加车刷新，防止新搭配插坑被删除
        insterOutfitYmal()
      })
    }, 100) // 延迟执行刷新，等触发加车成功addSkcCompleteStatus的watch
  }
  // 监听加车刷新事件
  appEventCenter.on('recommend-refresh-data', onRefreshData)
  onUnmounted(() => {
    appEventCenter.off('recommend-refresh-data', onRefreshData)
    clearTimeout(_refreshTimer)
  })

  return {
    isUseAddCartParams,
    isYmalAddBagRefreshing,
  }
}

export const useAfterAddWishRefresh = ({ initData }) => {
  const {
    fs_abt
  } = useMapGetters('productDetail/common', [
    'fs_abt',
  ])

  // 是否新增参数 add_to_board = 1
  const isUseAddBoardParams = ref(0)
  // 收藏成功后是否正在刷新
  const isYmalAddBoardRefreshing = ref(false)

  // ​收藏成功下锚到ymal时，需要刷新调用新策略
  const shouldRefreshRecAfterAddWish = fs_abt.value?.wishymal?.p?.wishrefresh === 'new'

  if (!shouldRefreshRecAfterAddWish) {
    return {
      isUseAddBoardParams,
      isYmalAddBoardRefreshing
    }
  } 

  const { goods_id } = useMapGetters('productDetail/common', [
    'goods_id',
  ])

  const {
    need_scroll_ymal_add_wish
  } = useMapStateV2('productDetail/Wish', [
    'need_scroll_ymal_add_wish'
  ])   

  const {
    update_scroll_ymal_add_wish,
  } = useMapMutations('productDetail/Wish', [
    'update_scroll_ymal_add_wish'
  ])

  // 监听收藏后是否滚动到 ymal
  watch(() => need_scroll_ymal_add_wish.value, val => {
    if (val && !isYmalAddBoardRefreshing.value) {
      isUseAddBoardParams.value++
      initData()
      update_scroll_ymal_add_wish(false)
    }
  })

  // 组件 init 时判断
  if (need_scroll_ymal_add_wish.value) {
    isUseAddBoardParams.value++
    update_scroll_ymal_add_wish(false)
  }

  // goods_id 变化时，重置状态
  watch(() => goods_id.value, () => {
    isUseAddBoardParams.value = 0
    isYmalAddBoardRefreshing.value = false
  })
  
  return {
    isUseAddBoardParams,
    isYmalAddBoardRefreshing
  }
}

let lockScrollListener = false
export const useTabLastScrollTop = ({
  TabRef,
  ListWrapRef,
}) => {
  const vm = getCurrentInstance()
  const state = {
    tabIndexTopMap: {},
  }

  function setTabScrollTopWithListDom(tabIndex) {
    const isBrowsingYmal = checkIsBrowsingYmal({ recTabEl: TabRef.value.$el, ymalWrapper: vm?.proxy?.$el })

    let _saveTop = 0
    if (isBrowsingYmal) {
      _saveTop = window.scrollY
    }
    state.tabIndexTopMap[tabIndex] = _saveTop
    console.log(`setTabScrollTopWithListDom`, tabIndex, _saveTop, state.tabIndexTopMap)
  }

  function setTabScrollTopAllEmpty() {
    state.tabIndexTopMap = {}
  }

  function setTabScrollTopToLastWithIndex(tabIndex) {
    // 如果没吸顶 return
    if (!checkIsBrowsingYmal({ recTabEl: TabRef.value.$el, ymalWrapper: vm?.proxy?.$el })) return

    // 锁定 scroll 事件
    lockScrollListener = true
    setTimeout(() => {
      lockScrollListener = false
    }, 60)

    const lastTop = state.tabIndexTopMap[tabIndex]

    console.log('setTabScrollTopToLastWithIndex', tabIndex, lastTop, state.tabIndexTopMap)

    // 记录了 lastTop 时，复原位置
    if (lastTop) {
      window.scrollTo(0, lastTop)
      return
    }

    // 吸顶时，没记录到 lastTop 时让 tab 商品回到最初位置 
    const { top: tabRefTop } = TabRef.value.$el?.getBoundingClientRect?.() || {}
    const { height: titleHeight = 0 } = vm?.proxy?.$el?.querySelector('.recommend-bottom-top_title')?.getBoundingClientRect() || {}
    const offsetTop = vm?.proxy?.$el.offsetTop
    window.scrollTo(0, offsetTop - Math.floor(tabRefTop) + Math.floor(titleHeight) + 2)
  }

  return {
    setTabScrollTopWithListDom,
    setTabScrollTopAllEmpty,
    setTabScrollTopToLastWithIndex
  }
}

/**
 * 底部推荐位曝光池处理
 */
export const useExposeGoodsPool = () => {

  const { updateRecommendGoods } = useMapActions('newProductDetail', ['updateRecommendGoods'])
  const {
    exposeGoods,
  } = useMapStateV2('newProductDetail/common', [
    'exposeGoods'
  ]) // todo_del

  const pushExposeGoodsPoolWithTargets = async ({ targets, isYmalTab } = {}) => {

    const tags = isYmalTab ? ['ymalRecTab'] : []
    // 获取 ymal 曝光，列表需要相关参数
    if (isYmalTab && unref(exposeGoods).status) {
      const targetsGoods = await updateRecommendGoods({ targets, type: 'ymal' })
      recommendExposureListInstance.pushQueen({
        targets: targetsGoods,
        isDomList: false,
        tags,
      })

      return
    }

    recommendExposureListInstance.pushQueen({
      targets,
      tags,
    })
  }

  return {
    pushExposeGoodsPoolWithTargets
  }
}

/**
 * 过滤已曝光的商品
 */
export const filterRecommendData = (list = []) =>{
  const goodsIds = recommendExposureListInstance.getTagList('ymalRecTab', 50) || []
  if(!goodsIds.length) return list
  return list.filter(item => {
    return goodsIds.indexOf(item.goods_id) === -1
  })

}
// ymal tab曝光池子
export const ymalExposureListInstance = new recommendExposureList({
  maxNum: 120,
})

export class AsyncTask {
  static taskCeaseSets = new Set()

  static addTask({ taskExecute, taskCease } = {}) {

    if (typeof taskCease !== 'function') return
    if (typeof taskExecute !== 'function') return

    this.taskCeaseSets.add(taskCease)

    const taskRemove = () => {
      return this.taskCeaseSets.delete(taskCease)
    }

    // 执行任务完成后删除
    taskExecute?.()?.finialy?.(() => {
      taskRemove()
    })

    return taskRemove
  }

  static cleanTask() {
    Array.from(this.taskCeaseSets).forEach(taskCease => {
      taskCease()
    })
  }
}


export const updateTabItemIdsMap = (tabItem, products = []) => {
  if (!tabItem) return
  const startIndex = tabItem.products.length
  if (products.length) {  // 增量更新
    products.forEach((item, index) => {
      if (!item.goods_id) return
      tabItem.itemIdsMap[item.goods_id] = startIndex + index + 1
    })
  } else {
    tabItem.products.forEach((item, index) => {
      if (!item.goods_id) return
      tabItem.itemIdsMap[item.goods_id] = index
    })
  }
}

export const filterRepeatGoods = (tabItem, products = []) => {
  if (!tabItem) return products
  return products.filter(item => {
    if (typeof tabItem.itemIdsMap[item.goods_id] === 'undefined') return true
    console.log('filterRepeatGoods', item.goods_id, tabItem.itemIdsMap[item.goods_id])
    return false
  })
}

const FLOOR_TAB_HEIGHT_REM = 1.18
function getHtmlFontSize() {
  return parseFloat(document.documentElement.style.fontSize)
}

// 是否正在浏览 ymal，判定标准 -> recommend tab 固定
function checkIsBrowsingYmal({ recTabEl, ymalWrapper }) {
  if (!recTabEl || !ymalWrapper) return false

  const titleRef = ymalWrapper.querySelector('.recommend-bottom-top')
  const { height: titleHeight } = titleRef.getBoundingClientRect()
  return recTabEl.offsetTop - ymalWrapper.offsetTop >= titleHeight + 1
}

// 沉浸式浏览 ymal (隐藏楼层 tab 和 底部加车 bar)
export const useBatheYmal = ({ showTab, tabRef }) => {
  const {
    fs_abt
  } = useMapGetters('productDetail/common', [
    'fs_abt',
  ])

  // ymal浏览区域楼层tab隐藏
  const ymalheadNew = computed(() => fs_abt.value?.ymalscan?.p?.ymalhead === 'new')
  // ymal浏览区域，下滑时不展示底部加车大按钮（区域内上滑要展示）
  const addcartbuttonYmalNone = computed(() => fs_abt.value?.ymalscan?.p?.addcartbutton === 'ymalnone')

  if (!ymalheadNew.value && !addcartbuttonYmalNone.value) return

  const vm = getCurrentInstance()

  const {
    update_show_add_cart_bar
  } = useMapMutations('productDetail/addBag', ['update_show_add_cart_bar'])

  const {
    goodsDetailInfo
  } = useMapStateV2(null, ['goodsDetailInfo'])

  const isSearchFloor = goodsDetailInfo.value?.productNavigationSearchInfo?.abtHit

  let throttleListener = null
  function removeListener() {
    if (throttleListener) {
      document.removeEventListener('scroll', throttleListener)
    }
  }

  watch(() => showTab.value, async (val) => {
    removeListener()
    if (val) {
      let lastScrollY = window.scrollY
      let totalOffset = 0

      await nextTick()

      const recTabEl = tabRef.value.$el
      if (!recTabEl) return

      function scrollListener () {
        if (lockScrollListener) return
        const currentScrollY = window.scrollY
        const offset = currentScrollY - lastScrollY
        lastScrollY = currentScrollY
        const floorTabRef = document.querySelector('#j-floor-tab')
        // 正在浏览 ymal
        const isBrowsingYmal = checkIsBrowsingYmal({ recTabEl, ymalWrapper: vm?.proxy?.$el })

        if (isBrowsingYmal) {
          if (addcartbuttonYmalNone.value) {
            update_show_add_cart_bar(offset < 0)
          }

          if (!ymalheadNew.value || isSearchFloor) return
          totalOffset += offset
          const floorTabHeight = Math.floor(FLOOR_TAB_HEIGHT_REM * getHtmlFontSize())
          if (Math.abs(totalOffset) <= floorTabHeight) {
            recTabEl.style.transform = `translate3d(0, -${totalOffset}px, 0)`
            floorTabRef.style.transform = `translate3d(0, -${totalOffset}px, 0)`
          } else {
            recTabEl.style.transform = `translate3d(0, -${Math.floor(floorTabHeight)}px, 0)`
            floorTabRef.style.transform = `translate3d(0, -${Math.floor(floorTabHeight)}px, 0)`
          }
        } else {
          update_show_add_cart_bar(true)
          recTabEl.style.transform = `translate3d(0, 0, 0)`
          if (floorTabRef) {
            floorTabRef.style.transform = `translate3d(0, 0, 0)`
          }
          totalOffset = 0
        }
      }

      throttleListener = throttle({
        func: scrollListener,
        wait: 10
      })
      
      document.addEventListener('scroll', throttleListener)
    }
  })

  onUnmounted(removeListener)

  const { goods_id } = useMapGetters('productDetail/common', [
    'goods_id',
  ])
  watch(() => goods_id.value, () => update_show_add_cart_bar(true))
}
